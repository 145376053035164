import React, { Component, Fragment } from 'react';
import { DateRangePicker } from 'react-date-range';
import {
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Label,
	Popover,
	PopoverBody
} from 'reactstrap';
import moment from 'moment';

import { jsDate } from '../utils';

const dateDisplayFormat = 'MMMM D, YYYY';

export default class DateRangePopover extends Component {
	state = {
		popoverOpen: false,
		staticRanges: [],
		startDate: null,
		dspStartDate: '',
		endDate: null,
		dspEndDate: ''
	};

	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
		this.onRangePick = this.onRangePick.bind(this);
	}

	componentDidUpdate({
		startDate: prevStartDate,
		endDate: prevEndDate,
		minDate: prevMinDate,
		maxDate: prevMaxDate,
		shouldUpdateStatic: prevShouldUpdateStatic
	}) {
		const {
			startDate,
			endDate,
			minDate,
			maxDate,
			shouldUpdateStatic = false
		} = this.props;

		if (
			prevMinDate !== minDate ||
			prevMaxDate !== maxDate ||
			(shouldUpdateStatic && shouldUpdateStatic !== prevShouldUpdateStatic)
		) {
			this.setState({ staticRanges: this.getStaticRanges() });
		}
		if (prevStartDate !== startDate || prevEndDate !== endDate) {
			const dates = this.getUpdatedDates(startDate, endDate);
			this.setState({ ...dates });
		}
	}

	getUpdatedDates() {
		const { startDate, endDate } = this.props;
		if (!startDate || !endDate) return {};
		return {
			startDate: startDate,
			dspStartDate: startDate.format(dateDisplayFormat),
			endDate: endDate,
			dspEndDate: endDate.format(dateDisplayFormat)
		};
	}

	getStaticRanges = () => {
		const {
			minDate,
			maxDate,
			startDate,
			endDate,
			newestDate,
			isShortRange = false,
			isSnapshots = false
		} = this.props;
		if (!minDate || !maxDate || !startDate || !endDate) return [];
		//max date in DataRangePopover
		const maxDateDR = !isSnapshots
			? moment.utc(newestDate).subtract(1, 'd')
			: moment.utc(newestDate);
		if (isShortRange) {
			return [
				{
					label: 'Past 3 years',
					range: () => ({
						startDate: jsDate(maxDateDR.clone().subtract(3, 'y')),
						endDate: jsDate(maxDate)
					}),
					isSelected: () => false
				},
				{
					label: 'All dates',
					range: () => ({
						startDate: minDate,
						endDate: maxDate
					}),
					isSelected: () => false
				},
				{
					label: 'Reset to default',
					range: () => ({
						startDate: jsDate(maxDateDR.clone().subtract(5, 'y')),
						endDate: jsDate(maxDate)
					}),
					isSelected: () => false
				}
			];
		} else
			return [
				{
					label: 'Past 3 months',
					range: () => ({
						startDate: jsDate(maxDateDR.clone().subtract(3, 'M')),
						endDate: jsDate(maxDate)
					}),
					isSelected: () => false
				},
				{
					label: 'Past 6 months',
					range: () => ({
						startDate: jsDate(maxDateDR.clone().subtract(6, 'M')),
						endDate: jsDate(maxDate)
					}),
					isSelected: () => false
				},
				{
					label: 'Past 9 months',
					range: () => ({
						startDate: jsDate(maxDateDR.clone().subtract(9, 'M')),
						endDate: jsDate(maxDate)
					}),
					isSelected: () => false
				},
				{
					label: 'Past year',
					range: () => ({
						startDate: jsDate(maxDateDR.clone().subtract(1, 'y')),
						endDate: jsDate(maxDate)
					}),
					isSelected: () => false
				},
				{
					label: 'Past 2 years',
					range: () => ({
						startDate: jsDate(maxDateDR.clone().subtract(2, 'y')),
						endDate: jsDate(maxDate)
					}),
					isSelected: () => false
				},
				{
					label: 'Previous calendar year',
					range: () => ({
						startDate: jsDate(moment().subtract(1, 'y').startOf('y')),
						endDate: jsDate(moment().subtract(1, 'y').endOf('y'))
					}),
					isSelected: () => false
				},
				{
					label: 'Previous 2 calendar years',
					range: () => ({
						startDate: jsDate(moment().subtract(2, 'y').startOf('y')),
						endDate: jsDate(moment().subtract(1, 'y').endOf('y'))
					}),
					isSelected: () => false
				},
				{
					label: 'All dates',
					range: () => ({
						startDate: minDate,
						endDate: maxDate
					}),
					isSelected: () => false
				},
				{
					label: 'Reset to default',
					range: () => ({
						startDate: jsDate(maxDateDR.clone().subtract(1, 'y')),
						endDate: jsDate(maxDate)
					}),
					isSelected: () => false
				}
			];
	};

	toggle() {
		this.setState({
			popoverOpen: !this.state.popoverOpen
		});
	}

	async onRangePick({ selection: { startDate, endDate } }) {
		startDate = moment(startDate);
		endDate = moment(endDate);
		await this.setState({
			startDate,
			endDate
		});

		if (startDate.valueOf() !== endDate.valueOf()) {
			setTimeout(async () => {
				await this.setState({
					dspStartDate: startDate.format(dateDisplayFormat),
					dspEndDate: endDate.format(dateDisplayFormat)
				});
				this.toggle();
				this.props.onDateRangeSelected({
					startDate,
					endDate
				});
			}, 600);
		}
	}

	render() {
		const { id, minDate, maxDate, disabled } = this.props;
		const {
			popoverOpen,
			staticRanges,
			startDate,
			dspStartDate,
			endDate,
			dspEndDate
		} = this.state;

		if (startDate && endDate) {
			return (
				<Fragment>
					<Label
						for={'DateRange-' + this.props.id}
						className="date-range-selector mb-3 mr-3 align-bottom"
					>
						<span className="sr-only">Please select a date range</span>
						<InputGroup>
							<InputGroupAddon addonType="prepend">
								<InputGroupText>
									<i className="fa fa-calendar" />
								</InputGroupText>
							</InputGroupAddon>
							<Input
								type="text"
								id={'DateRange-' + id}
								onClick={this.toggle}
								onKeyPress={this.toggle}
								value={`${dspStartDate} - ${dspEndDate}`}
								readOnly
								bsSize="sm"
								style={{ width: 290 }}
								disabled={disabled}
							/>
						</InputGroup>
					</Label>
					<Popover
						placement="bottom"
						isOpen={popoverOpen}
						target={'DateRange-' + id}
						toggle={this.toggle}
					>
						<PopoverBody>
							<DateRangePicker
								months={1}
								firstDayOfWeek={1}
								showSelectionPreview={false}
								moveRangeOnFirstSelection={false}
								direction="horizontal"
								minDate={jsDate(minDate)}
								maxDate={jsDate(maxDate)}
								onChange={this.onRangePick}
								staticRanges={staticRanges}
								inputRanges={[]}
								ranges={[
									{
										key: 'selection',
										startDate: jsDate(startDate),
										endDate: jsDate(endDate)
									}
								]}
							/>
						</PopoverBody>
					</Popover>
				</Fragment>
			);
		}
		return null;
	}
}
