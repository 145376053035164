const chartStyle = {
	fill: false,
	lineTension: 0.4,
	borderWidth: 1,
	borderJoinStyle: 'round',
	pointStyle: 'circle',
	pointRadius: 0,
	pointHoverRadius: 3,
	pointBorderWidth: 0
};

let chartOptions = {
	responsive: true,
	maintainAspectRatio: true,
	animation: {
		duration: 0
	},
	scales: {
		xAxes: [
			{
				type: 'time',
				distribution: 'linear',
				scaleLabel: {
					display: true
					// labelString: 'Time'
				},
				time: {
					displayFormats: {
						week: 'D MMM YY',
						month: 'D MMM YY'
					}
				}
			}
		],
		yAxes: [
			{
				distribution: 'linear',
				scaleLabel: {
					display: true,
					labelString: 'Inactive Capacity'
				},
				ticks: {
					beginAtZero: false
				}
			}
		]
	},
	title: {
		display: false
	},
	legend: {
		labels: {
			usePointStyle: true,
			padding: 20,
			filter: (item) => {
				return !item.text.includes('Max') && !item.text.includes('Min');
			}
		}
	}
};

const snapshotsChartStyle = {
	...chartStyle,
	pointHoverRadius: 2.5,
	pointRadius: 2.5,
	pointHitRadius: 2.5
};

const plugins = [
	{
		afterDraw: function (chart) {
			if (chart.data.datasets[0].data.length === 0) {
				var ctx = chart.chart.ctx;
				ctx.save();
				ctx.textAlign = 'center';
				ctx.textBaseline = 'middle';
				ctx.font = '22px Arial';
				ctx.fillStyle = 'gray';
				ctx.fillText(
					'No data available',
					chart.chart.width / 2,
					chart.chart.height / 2
				);
				ctx.restore();
			}
		}
	}
];

export { chartStyle, chartOptions, snapshotsChartStyle, plugins };
