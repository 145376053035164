import { Auth } from 'aws-amplify';
import React, { Component, Fragment } from 'react';
import { Button, ButtonGroup, UncontrolledTooltip } from 'reactstrap';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import ReactGA from 'react-ga4';
import { Line, Bar } from 'react-chartjs-2';
import { toastr } from 'react-redux-toastr';

import { downloadKml, fetchSmelterParamList } from 'actions';
import { StripPlot } from 'components';
import {
	rStripChartStyle,
	rStripChartOptions,
	pStripChartOptions,
	barMonthChartOptions,
	barWeekChartOptions,
	barYearChartOptions
} from '../screens/Steel/Smelters/chartConfig';

import colours from '../_colours.scss';
import './StripPlotContainer.scss';

class StripPlotContainer extends Component {
	constructor(props) {
		super(props);
		this.csvActivityLinkRef = React.createRef();

		this.state = {
			csvActivityData: [],
			showProductionPlot: false,
			showNRIScore: false,
			prodFreq: 'month',
			canDownload: true
		};
	}

	async componentDidMount() {
		const { title, noDataWarning, isSnapshots } = this.props;
		if (noDataWarning) {
			toastr.warning(title, 'No data for chosen date range');
		}
		const authUser = await Auth.currentAuthenticatedUser();
		this.userSub = authUser.attributes['sub'] || '';
		if (isSnapshots) {
			this.setState({ prodFreq: 'day' });
		}
	}

	componentDidUpdate(
		{
			alignedCapacityData: prevAlignedCapacityData,
			alignedActivityData: prevAlignedActivityData
		},
		{
			csvActivityData: prevCsvActivityData,
			showProductionPlot: prevShowProductionPlot
		}
	) {
		const { csvActivityData } = this.state;
		const { title, noDataWarning, alignedCapacityData, alignedActivityData } =
			this.props;

		if (csvActivityData.length > prevCsvActivityData.length) {
			this.csvActivityLinkRef.current.link.click();
		}

		if (
			noDataWarning &&
			(prevAlignedActivityData.length !== alignedActivityData.length ||
				prevAlignedCapacityData.length !== alignedCapacityData.length)
		) {
			toastr.warning(title, 'No data for chosen date range');
		}
	}

	downloadParamList = async () => {
		if (this.state.csvActivityData.length) {
			this.csvActivityLinkRef.current.link.click();
			return;
		}
		const {
			smelterId,
			title,
			type,
			activityData,
			csvDateFormat,
			capacityData
		} = this.props;
		// API call to get data
		try {
			const response = await fetchSmelterParamList({ smelterId, type });
			const aData = activityData.map(({ t, p, r }) => ({
				t: moment.utc(t).format(csvDateFormat),
				p,
				r
			}));

			const pData = response[title].data.map(
				({
					capture_date,
					hotspots,
					low_count,
					medium_count,
					high_count,
					invalid_frac,
					p_data_age,
					s_hotspot,
					data_age
				}) => ({
					t: moment.utc(capture_date).format(csvDateFormat),
					hs: hotspots,
					hc: high_count,
					mc: medium_count,
					lc: low_count,
					if: invalid_frac,
					da: p_data_age,
					shs: s_hotspot,
					daps: data_age
				})
			);

			//we need to know max date from pData in order to fill capacityData with missing values
			const maxDate = moment.utc(pData[pData.length - 1].t).add(1, 'days');
			const extendedCapacityData = [];
			//exclude 'unknown' - each 'unknown' is 'on' or 'off'
			const fCapacityData = capacityData.filter((c) => c.v);

			fCapacityData.forEach((c, i) => {
				const diff =
					(i < fCapacityData.length - 1 &&
						moment.utc(fCapacityData[i + 1].t).diff(c.t, 'days')) ||
					maxDate.diff(c.t, 'days');
				for (let j = 0; j < diff; j++) {
					extendedCapacityData.push({
						t: moment.utc(c.t).add(j, 'days').format(csvDateFormat),
						v: c.v === '1' ? 'on' : 'off'
					});
				}
			});

			// merge extendedCapacityData to pData
			const extendedPData = pData.map((p) => ({
				...extendedCapacityData.find((c) => c.t === p.t && c),
				...p
			}));

			//merge two arrays, pData.length <= aData.length
			//find element in aDate with the same capture_date as the first capture_date in pData and merge data from the portion of aData and pData
			const startIdx = aData.findIndex((a) => a.t === extendedPData[0].t);
			const aData_1 = aData.slice(0, startIdx);
			//aData_2 and pData have the same start date
			const aData_2 = aData.slice(startIdx);
			const mData_2 = aData_2.map(({ t, a, r }, i) =>
				Object.assign({}, { ...{ t, a, r } }, { ...extendedPData[i] })
			);
			const mData = [...aData_1, ...mData_2];
			this.setState({ csvActivityData: mData });
		} catch (e) {
			console.log('Error while downloading param list ', e);
		}
	};

	render() {
		const {
			alignedActivityData,
			// capacityData,
			activityData,
			alignedCapacityData,
			weeklyProdData,
			allWeeklyProdData,
			monthlyProdData,
			allMonthlyProdData,
			yearlyProdData,
			allYearlyProdData,
			csvDateFormat,
			idx,
			maxDate,
			newestDate,
			title,
			pState,
			startDate,
			endDate,
			isSnapshots
		} = this.props;
		const reducedTitle = title.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
		const kmlButtonId = `kmlDownload${idx}`;
		// const csvButtonId = `csvDownload${idx}`;
		// const csvActivityButtonId = `csvActivityDownload${idx}`;
		const productionPlotButtonId = `productionPlotButtonId${idx}`;
		const csvActProdButtonId = `csvActProdDownload${idx}`;

		// const { csvActivityData, showProductionPlot } = this.state;
		const { showProductionPlot, prodFreq, showNRIScore } = this.state;

		let prodPlotDatasets = {};

		if (prodFreq === 'day') {
			prodPlotDatasets = {
				data: alignedActivityData.map(({ t, p }) => {
					return {
						t: moment.utc(t).format('YYYY-MM-DD'),
						y: p
					};
				}),
				...rStripChartStyle
			};
		} else if (prodFreq === 'week') {
			prodPlotDatasets = {
				data: weeklyProdData.map(({ t, p }) => {
					return {
						t,
						y: p
					};
				})
			};
		} else if (prodFreq === 'month') {
			prodPlotDatasets = {
				data: monthlyProdData.map(({ t, p }) => {
					return {
						t,
						y: p
					};
				})
			};
		} else if (prodFreq === 'year') {
			prodPlotDatasets = {
				data: yearlyProdData.map(({ t, p }) => {
					return {
						t,
						y: p
					};
				})
			};
		}
		const barDatasets =
			prodFreq === 'week' || prodFreq === 'month' || prodFreq === 'year'
				? prodPlotDatasets.data.map(({ y }) => y)
				: [];
		const labels =
			prodFreq === 'week' || prodFreq === 'month' || prodFreq === 'year'
				? prodPlotDatasets.data.map(({ t }) => t)
				: [];

		const barData = {
			labels,
			datasets: [
				{
					data: barDatasets,
					backgroundColor: colours.earthiDeepSea
				}
			]
		};

		const stripNRIScoreDatasets = [
			{
				data: alignedActivityData.map(({ t, nr }) => {
					return {
						t: moment.utc(t).format('YYYY-MM-DD'),
						y: nr
					};
				}),
				...rStripChartStyle
			}
		];

		const stripPlotRawDatasets = [
			{
				data: alignedActivityData.map(({ t, r }) => {
					return {
						t: moment.utc(t).format('YYYY-MM-DD'),
						y: r
					};
				}),
				...rStripChartStyle
			}
		];

		const csvActivityDailyProduction = activityData.map(({ t, nr, p }) => ({
			t: moment.utc(t).format(csvDateFormat),
			nr,
			p
		}));

		const csvWeeklyProduction = allWeeklyProdData.map(({ t, p }) => ({
			wt: t,
			wp: p
		}));

		const csvMonthlyProduction = allMonthlyProdData.map(({ t, p }) => ({
			mt: t,
			mp: p
		}));
		const csvYearlyProduction = allYearlyProdData.map(({ t, p }) => ({
			yt: t,
			yp: p
		}));

		const zip = (...arrs) => {
			return arrs.reduce((a, arr) => {
				arr.forEach((x, i) => Object.assign((a[i] = a[i] || {}), x));
				return a;
			}, []);
		};

		const zippedActivityProduction = zip(
			csvActivityDailyProduction,
			csvWeeklyProduction,
			csvMonthlyProduction,
			csvYearlyProduction
		);

		// console.log(JSON.stringify(result));

		return (
			<div className="mb-2" key={`stripPlot${idx}`}>
				<div className="mb-1 mt-2 pull-right">
					{showProductionPlot && (
						<Fragment>
							{this.state.canDownload && (
								<Fragment>
									<CSVLink
										id={csvActProdButtonId}
										filename={`${title}-activity-production-data.csv`}
										headers={[
											{ label: 'Capture time', key: 't' },
											{ label: 'Activity', key: 'nr' },
											{ label: 'Daily production (t)', key: 'p' },
											{ label: 'Week', key: 'wt' },
											{ label: 'Weekly production (t)', key: 'wp' },
											{ label: 'Month', key: 'mt' },
											{ label: 'Monthly production (t)', key: 'mp' },
											{ label: 'Year', key: 'yt' },
											{ label: 'Annual production (t)', key: 'yp' }
										]}
										data={zippedActivityProduction.map(
											({ t, nr, p, wt, wp, mt, mp, yt, yp }) => {
												return { t, nr, p, wt, wp, mt, mp, yt, yp };
											}
										)}
										className="btn btn-success btn-sm mr-3"
										onClick={() => {
											ReactGA.event({
												category: 'steel_site_data_download',
												action: 'download',
												label: this.userSub
											});
											return true;
										}}
									>
										<i className="fa fa-download" aria-hidden="true" />
									</CSVLink>
									<UncontrolledTooltip
										placement="top"
										delay={500}
										target={csvActProdButtonId}
										trigger="click hover"
									>
										<i className="fa fa-download mr-3" aria-hidden="true" />{' '}
										Download this data
									</UncontrolledTooltip>
								</Fragment>
							)}

							<Button
								outline
								size="sm"
								color="secondary"
								onClick={() =>
									this.setState({
										prodFreq: !showNRIScore ? '' : 'day',
										showNRIScore: !showNRIScore
									})
								}
								active={showNRIScore}
								disabled={false}
								aria-label="Click to display daily production"
								className="mr-2"
							>
								Activity
							</Button>

							<ButtonGroup className="mr-2 flex-shrink-0">
								<Button
									outline
									size="sm"
									color="secondary"
									onClick={() =>
										this.setState({
											prodFreq: 'day',
											showNRIScore: false
										})
									}
									active={prodFreq === 'day'}
									disabled={false}
									aria-label="Click to display daily production"
								>
									Daily
								</Button>
								<Button
									outline
									size="sm"
									color="secondary"
									onClick={() =>
										this.setState({ prodFreq: 'week', showNRIScore: false })
									}
									active={prodFreq === 'week'}
									disabled={isSnapshots}
									aria-label="Click to display weekly production"
								>
									Weekly
								</Button>
								<Button
									outline
									size="sm"
									color="secondary"
									onClick={() =>
										this.setState({
											prodFreq: 'month',
											showNRIScore: false
										})
									}
									active={prodFreq === 'month'}
									disabled={isSnapshots}
									aria-label="Click to display monthly production"
								>
									Monthly
								</Button>
								<Button
									outline
									size="sm"
									color="secondary"
									onClick={() =>
										this.setState({ prodFreq: 'year', showNRIScore: false })
									}
									active={prodFreq === 'year'}
									disabled={isSnapshots}
									aria-label="Click to display yearly production"
								>
									Yearly
								</Button>
							</ButtonGroup>
						</Fragment>
					)}
					<ButtonGroup>
						{this.state.canDownload && (
							<Fragment>
								<Button
									id={kmlButtonId}
									color="success"
									size="sm"
									disabled={isSnapshots}
									onClick={() => {
										downloadKml({ id: this.props.smelterId });
										ReactGA.event({
											category: 'steel_site_kml_download',
											action: 'download',
											label: this.userSub
										});
										return true;
									}}
								>
									<i className="fa fa-globe" aria-hidden="true" />
									<span className="sr-only">Download kml</span>
								</Button>
								<UncontrolledTooltip
									placement="top"
									delay={500}
									target={kmlButtonId}
									trigger="click hover"
								>
									<i className="fa fa-download mr-2" aria-hidden="true" />{' '}
									Download this data in KML format.
								</UncontrolledTooltip>
							</Fragment>
						)}
						{/* <Fragment>
						<Button
							id={csvActivityButtonId}
							color="success"
							disabled
							size="sm"
							onClick={this.downloadParamList}
						>
							<i className="fa fa-line-chart" aria-hidden="true" />
						</Button>
						<CSVLink
							ref={this.csvActivityLinkRef}
							data={csvActivityData}
							headers={[
								{ label: 'Date', key: 't' },
								{ label: 'State', key: 'v' },
								{ label: 'Activity dispersion', key: 'a' },
								{ label: 'Raw index score', key: 'r' },
								{ label: 'Hotspots', key: 'hs' },
								{ label: 'Low count', key: 'lc' },
								{ label: 'Medium count', key: 'mc' },
								{ label: 'High count', key: 'hc' },
								{ label: 'Cloud pct', key: 'if' },
								{ label: 'Data age (prime)', key: 'da' },
								{ label: 'Secondary hotspot', key: 'shs' },
								{ label: 'Data age (prime + secondary)', key: 'daps' }
							]}
							filename={`${title}-activity-dispersion-and-raw-index-score-data.csv`}
							className="d-none"
							onClick={() => {
								ReactGA.event({
									category: 'smeltersMap',
									action:
										'activity dispersion and raw index score data downloaded',
									label: `${title}-activity-dispersion-and-raw-index-score-data-and-related-data.csv`
								});
								return true;
							}}
						/>
					</Fragment>
					<UncontrolledTooltip
						placement="top"
						delay={500}
						target={csvActivityButtonId}
						trigger="click hover"
					>
						<i className="fa fa-download mr-2" aria-hidden="true" /> Download
						Activity Dispersion, Raw Index Score and related data in CSV format.
					</UncontrolledTooltip> */}
						{/* <Fragment>
						<Button id={csvButtonId} color="success" disabled size="sm">
							<i className="fa fa-table" aria-hidden="true" />
						</Button> */}
						{/* <CSVLink
							id={csvButtonId}
							filename={`${title}-inactive-capacity-data.csv`}
							headers={[
								{ label: 'Capture time', key: 't' },
								{ label: 'State', key: 'v' }
							]}
							data={capacityData.map(({ t, v }) => {
								let dspState = 'unknown';
								if (v === 1) {
									dspState = 'on';
								} else if (v === 0) {
									dspState = 'off';
								}
								return { t: moment.utc(t).format(csvDateFormat), v: dspState };
							})}
							className="btn btn-success btn-sm"
							onClick={() => {
								ReactGA.event({
									category: 'smeltersMap',
									action: 'inactive capacity data downloaded',
									label: `${title}-inactive-capacity-data.csv`
								});
								return true;
							}}
						>
							<i className="fa fa-table" aria-hidden="true" />
						</CSVLink> */}
						{/* </Fragment>
					<UncontrolledTooltip
						placement="top"
						delay={500}
						target={csvButtonId}
						trigger="click hover"
					>
						<i className="fa fa-download mr-2" aria-hidden="true" /> Download
						Inactive Capacity Index data in CSV format.
					</UncontrolledTooltip> */}
						<Button
							id={productionPlotButtonId}
							color={showProductionPlot ? 'secondary' : 'success'}
							size="sm"
							onClick={() =>
								this.setState({ showProductionPlot: !showProductionPlot })
							}
						>
							<i className="fa fa-bar-chart" aria-hidden="true" />
							<span className="sr-only">Toggle Production plot</span>
						</Button>
						<UncontrolledTooltip
							placement="top"
							delay={500}
							target={productionPlotButtonId}
							trigger="click hover"
						>
							<i className="fa fa-eye-slash" aria-hidden="true" /> Toggle
							Production plot visibility
						</UncontrolledTooltip>
					</ButtonGroup>
				</div>
				<div>
					<h6 className="mb-0 mt-3 d-inline-block">
						{pState ? (
							<Fragment>
								{title}
								<span className="asterisk">*</span>
							</Fragment>
						) : (
							title
						)}
					</h6>{' '}
					&nbsp;&nbsp;&nbsp;
					<span className="index-dates">
						{startDate ? `First date in index: ${startDate}` : ''}
					</span>
					<span className="index-dates">
						{endDate ? `, Last date in index: ${endDate}` : ''}
					</span>
				</div>
				{showProductionPlot && prodFreq === 'day' && !showNRIScore && (
					<Fragment>
						<div className="chart-container">
							<Line
								data={{ datasets: [prodPlotDatasets] }}
								options={pStripChartOptions}
							/>
						</div>
						<StripPlot
							id={`${reducedTitle}_1`}
							data={alignedCapacityData.map(({ t, v }) => ({ t, v: 0 }))}
							maxDate={maxDate}
							newestDate={newestDate}
							minWidth={700}
							plotHeight={5}
							axisHeight={0}
							tickHeight={0}
							hoverHeight={0}
							margin={{
								left: 5,
								top: 0,
								right: 5,
								bottom: 5
							}}
						/>
					</Fragment>
				)}
				{showProductionPlot && prodFreq === 'month' && !showNRIScore && (
					<div className="bar-chart-container">
						<Bar data={{ ...barData }} options={barMonthChartOptions} />
					</div>
				)}
				{showProductionPlot && prodFreq === 'week' && !showNRIScore && (
					<div className="bar-chart-container">
						<Bar data={{ ...barData }} options={barWeekChartOptions} />
					</div>
				)}
				{showProductionPlot && prodFreq === 'year' && !showNRIScore && (
					<div className="bar-chart-container">
						<Bar data={{ ...barData }} options={barYearChartOptions} />
					</div>
				)}
				{showProductionPlot && showNRIScore && (
					<Fragment>
						<div className="chart-container">
							<Line
								data={{ datasets: stripNRIScoreDatasets }}
								options={rStripChartOptions}
							/>
						</div>
						<StripPlot
							id={`${reducedTitle}_1`}
							data={alignedCapacityData.map(({ t, v }) => ({ t, v: 0 }))}
							maxDate={maxDate}
							newestDate={newestDate}
							minWidth={700}
							plotHeight={5}
							axisHeight={0}
							tickHeight={0}
							hoverHeight={0}
							margin={{
								left: 5,
								top: 0,
								right: 5,
								bottom: 5
							}}
						/>
					</Fragment>
				)}
				{!showProductionPlot && (
					<Fragment>
						<div className="chart-container">
							<Line
								data={{ datasets: stripPlotRawDatasets }}
								options={rStripChartOptions}
							/>
						</div>
						<StripPlot
							id={reducedTitle}
							data={alignedCapacityData}
							maxDate={maxDate}
							newestDate={newestDate}
							minWidth={700}
							plotHeight={40}
							axisHeight={40}
							tickHeight={5}
							hoverHeight={4}
							margin={{
								left: 5,
								top: 0,
								right: 5,
								bottom: 0
							}}
						/>
					</Fragment>
				)}
			</div>
		);
	}
}

export default StripPlotContainer;
