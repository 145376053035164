import colours from '../../_colours.scss';

const colourPalette = [
	'#4053d3',
	'#ddb310',
	'#b51d14',
	'#00beff',
	'#fb49b0',
	'#00b25d',
	'#cacaca'
];

//style for Inactive Capacity index
const fChartStyle = {
	fill: false,
	borderWidth: 1,
	borderJoinStyle: 'round',
	pointStyle: 'circle',
	pointBackgroundColor: '#fff',
	pointBorderWidth: 1,
	pointHoverRadius: 2,
	pointHoverBorderColor: 'rgba(86,86,86,1)',
	pointHoverBorderWidth: 1,
	pointRadius: 2,
	pointHitRadius: 5
};

const annotationsStem = {
	events: ['mouseover', 'mouseout'],
	annotations: []
};

const annotationStem = {
	drawTime: 'afterDatasetsDraw',
	type: 'line',
	mode: 'vertical',
	scaleID: 'x-axis-0',
	borderColor: colours.earthiVividGreen,
	borderWidth: 2,
	label: {
		backgroundColor: colours.earthiGrassGreen,
		fontSize: 12,
		fontStyle: 'normal',
		fontColor: colours.earthiWhite,
		cornerRadius: 3,
		enabled: false,
		position: 'bottom',
		yAdjust: 20
	},
	onMouseover: function (e) {
		const element = this;
		for (let k in element.chartInstance.annotation.elements) {
			let anno = element.chartInstance.annotation.elements[k];
			if (anno.id !== this.id) {
				anno.options.borderColor = 'rgba(117, 182, 38, 0.3)';
			}
		}
		element.options.borderColor = colours.earthiGrassGreen;
		element.options.borderWidth = 3;
		element.options.label.enabled = true;
		element.chartInstance.update();
		element.chartInstance.chart.canvas.style.cursor = 'pointer';
	},
	onMouseout: function (e) {
		const element = this;
		setTimeout(() => {
			for (let k in element.chartInstance.annotation.elements) {
				let anno = element.chartInstance.annotation.elements[k];
				if (anno.id !== this.id) {
					anno.options.borderColor = colours.earthiVividGreen;
				}
			}
			element.options.borderColor = colours.earthiVividGreen;
			element.options.borderWidth = 2;
			element.options.label.enabled = false;
			element.chartInstance.update();
		}, 200);
		element.chartInstance.chart.canvas.style.cursor = 'initial';
	}
};

//options for Inactive Capacity index
const fChartOptions = {
	responsive: true,
	maintainAspectRatio: true,
	animation: {
		duration: 0
	},
	scales: {
		xAxes: [
			{
				type: 'time',
				distribution: 'linear',
				scaleLabel: {
					display: true
					// labelString: 'Time'
				},
				time: {
					displayFormats: {
						hour: 'D MMM YY',
						day: 'D MMM YY',
						week: 'D MMM YY',
						month: 'D MMM YY',
						quarter: 'D MMM YY',
						year: 'YYYY'
					}
				}
			}
		],
		yAxes: [
			{
				distribution: 'linear',
				id: 'total-pixels-count',
				position: 'left',
				scaleLabel: {
					display: true,
					labelString: 'Total Pixels'
				},
				ticks: {
					beginAtZero: false
					// min: 0
				}
			}
		]
	},
	title: {
		display: false
	},
	legend: {
		labels: {
			usePointStyle: true,
			padding: 20,
			filter: (item) => {
				return (
					!item.text.includes('Max') &&
					!item.text.includes('Min') &&
					!item.text.includes('threshold')
				);
			}
		}
	}
};

const genChartStyle = function ({ size, type, isSnapshots = false }) {
	const style = fChartStyle;

	if (size === 'sm') {
		return {
			...style,
			...{
				pointHoverRadius: 3.5,
				pointRadius: 3.5,
				pointHitRadius: 3.5
			}
		};
	} else if (size === 'md') {
		return {
			...style,
			...{
				pointHoverRadius: 2.5,
				pointRadius: 2.5,
				pointHitRadius: 2.5
			}
		};
	} else if (size === 'lg' && isSnapshots) {
		return {
			...style,
			...{
				pointHoverRadius: !isSnapshots ? 1.5 : 2.5,
				pointRadius: !isSnapshots ? 1.5 : 2.5,
				pointHitRadius: !isSnapshots ? 1.5 : 2.5
			}
		};
	} else if (size === 'xl') {
		return {
			...style,
			...{
				pointHoverRadius: !isSnapshots ? 1 : 2,
				pointRadius: !isSnapshots ? 1 : 2,
				pointHitRadius: !isSnapshots ? 1 : 2
			}
		};
	} else if (size === 'xxl') {
		return {
			...style,
			...{
				pointHoverRadius: 0.5,
				pointRadius: 0,
				pointHitRadius: 0.5
			}
		};
	}

	return {
		...style
	};
};

const plugins = [];
export {
	colourPalette,
	fChartStyle,
	fChartOptions,
	genChartStyle,
	annotationsStem,
	annotationStem,
	plugins
};
