import React, { Component, Fragment } from 'react';
import {
	Button,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	UncontrolledTooltip
} from 'reactstrap';

export default class InlineHelp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: false
		};

		this.toggle = this.toggle.bind(this);
	}

	toggle() {
		this.setState({
			modal: !this.state.modal
		});
	}

	render() {
		const { tooltipId, tooltip } = this.props;

		return (
			<Fragment>
				<Button
					id={tooltipId}
					color="info"
					className="ml-3"
					onClick={this.toggle}
					style={{ padding: '7px 12px' }}
				>
					<i className="fa fa-question-circle fa-lg" />
					<span className="sr-only">Help</span>
				</Button>
				<UncontrolledTooltip
					placement="left"
					delay={500}
					target={tooltipId}
					trigger="hover"
				>
					{tooltip}
				</UncontrolledTooltip>
				<Modal
					fade
					size="lg"
					isOpen={this.state.modal}
					toggle={this.toggle}
					className={this.props.className}
					tabIndex="-1"
					role="dialog"
				>
					<ModalHeader toggle={this.toggle} tag="h4">
						{this.props.title}
					</ModalHeader>
					<ModalBody>{this.props.children}</ModalBody>
					<ModalFooter>
						<Button color="secondary" onClick={this.toggle}>
							Close
						</Button>
					</ModalFooter>
				</Modal>
			</Fragment>
		);
	}
}
