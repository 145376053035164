import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Auth } from 'aws-amplify';

import DocumentTitle from 'react-document-title';
import {
	Alert,
	Button,
	ButtonGroup,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Form,
	Input,
	Label,
	Row,
	Progress
	// UncontrolledTooltip
} from 'reactstrap';
import { Line, Chart } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import ReactGA from 'react-ga4';

import {
	fetchLastUploadDate,
	resetLastUploadDate,
	fetchSteelSmelterProductionSubsites,
	resetSteelSmelterProductionSubsites,
	fetchCsvSteelProductionDataSubsites,
	fetchLocations,
	resetLocations,
	fetchGroups,
	resetGroups,
	fetchWorldSteelRegions,
	fetchWorldSteelSubsitesData,
	fetchSiteTypes
} from 'actions';
import {
	colourPalette,
	pChartOptions,
	pwChartOptions,
	pmChartOptions,
	pyChartOptions,
	genChartStyle,
	plugins
} from './chartConfig';
import { Crumbs } from 'containers';
import { DateRangePopover, LoaderButton } from 'components';
import MultiSelect from 'components/multiselect/MultiSelect';
import {
	getAlphaNumeric,
	capitaliseAll,
	mergeArrays,
	zipArrays,
	mergeSubArraysBasedOnKey,
	groupByKey,
	getUniqueObjects
} from '../../../utils';

import './Smelters.scss';

const config = {
	dateRangeDayLimit: 10,
	apiDateFormat: 'YYYYMMDD',
	dspDateFormat: 'YYYY-MM-DD',
	globalRegion: {
		name: 'Production',
		colour: '#FFA500',
		title: 'Global'
	},
	combinedRegion: {
		name: 'Combined production',
		colour: '#FFA500'
	},
	multipleSelectionLimit: 5
};

class SteelSmeltersProductionSubsites extends Component {
	constructor(props) {
		super(props);

		this.csvFullDownloadLink = React.createRef();

		this.state = {
			groupType: 'region',
			global: true,
			selectedGrouping: {},
			selected: 'regions',
			compareCombine: '',
			collapse: 'daily',
			isCollapsing: 'false',
			dateMin: moment.utc('2016-03-01'),
			dateMax: moment.utc(),
			dateFrom: moment.utc(),
			dateTo: moment.utc(),
			isInitDateRange: false,
			isInitFinished: false,
			reinit: false,
			shortDateRange: false,
			showDataWarning: false,
			canDownload: true,

			csvFull: {
				csvHeaders: [],
				csvData: []
			},
			isDownloadingAll: false,
			wsdSelected: false,
			worldSteelRegions: [],
			worldSteelData: []
		};
		this.userRole = '';
		this.userSub = '';
		this.isSnapshots = false;
	}

	async componentDidMount() {
		plugins.forEach((plugin) => {
			//register plugin if it is not already registered
			if (!Chart.pluginService._plugins.includes(plugin)) {
				Chart.pluginService.register(plugin);
			}
		});

		const {
			match: {
				params: { sType }
			},
			locations: { countries },
			siteTypes,
			groups
		} = this.props;
		const authUser = await Auth.currentAuthenticatedUser();
		this.userRole = authUser.attributes['custom:userRole'] || '';
		this.isSnapshots =
			this.userRole === 'user' &&
			!authUser.signInUserSession.idToken.payload['cognito:groups']?.length;
		this.userSub = authUser.attributes['sub'] || '';
		if (!siteTypes.length) {
			this.props.fetchSiteTypes();
		} else {
			this.getWorldSteelRegions();
		}
		if (!countries.length) {
			this.props.fetchLocations({ siteType: `${sType}` });
		}
		if (!Object.keys(groups).length) {
			this.props.fetchGroups({ siteType: `${sType}` });
		}
		this.props.fetchLastUploadDate({ commodity: 'steel' });
	}

	componentDidUpdate(
		{
			lastUploadDate: prevLastUploadDate,
			steelSmelterProduction: prevSteelSmelterData,
			match: {
				params: { sType: prevsType }
			},
			locations: { regions: prevRegions, countries: prevCountries },
			siteTypes: prevSiteTypes,
			groups: prevGroups
		},

		{
			isInitDateRange: prevIsInitDateRange,
			isInitFinished: prevIsInitFinished,
			dateFrom: prevDateFrom,
			dateTo: prevDateTo,
			selected: prevSelected,
			selectedGrouping: prevSelectedGrouping,
			global: prevGlobal,
			collapse: prevCollapse,
			wsdSelected: prevWsdSelected,
			worldSteelData: prevWorldSteelData,
			isDownloadingAll: prevIsDownloadingAll
		}
	) {
		const {
			lastUploadDate,
			steelSmelterProduction,
			match: {
				params: { sType }
			},
			locations: { regions, countries },
			siteTypes,
			groups
		} = this.props;
		const {
			isInitDateRange,
			isInitFinished,
			dateFrom,
			dateTo,
			dateMax,
			selected,
			selectedGrouping,
			global,
			collapse,
			wsdSelected,
			worldSteelData,
			isDownloadingAll
		} = this.state;

		if (siteTypes.length > prevSiteTypes.length) {
			this.getWorldSteelRegions();
		}
		if (countries.length !== prevCountries.length && !countries.length) {
			this.props.fetchLocations({ siteType: `${sType}` });
		}
		if (
			Object.keys(groups).length !== Object.keys(prevGroups).length &&
			!Object.keys(groups).length
		) {
			this.props.fetchGroups({ siteType: `${sType}` });
		}
		if (this.state.reinit) return;
		//fetchSteelNewestDate requested in ComponentDidMount(), ensure newestDate is received
		if (lastUploadDate === '') {
			return;
		}
		//Date setting
		if (lastUploadDate !== prevLastUploadDate) {
			this.updateDateHandling({ lastUploadDate });
			return;
		}

		if (
			(isInitDateRange !== prevIsInitDateRange && regions.length) ||
			(isInitDateRange && regions.length > prevRegions.length)
		) {
			this.setState({ isInitFinished: true });
			return;
		}

		if (isInitFinished !== prevIsInitFinished) {
			if (regions.length === 1) {
				this.setState(
					{
						groupType: 'country',
						selected: 'countries',
						global: false,
						selectedGrouping: {}
					},
					() => this.handleMultipleSelections(countries)
				);
			} else {
				this.filterData();
			}
			return;
		}

		if (sType !== prevsType) {
			const oldRegions = regions;
			if (countries.length > 1) {
				this.props.resetLocations();
			}
			this.props.resetGroups();
			this.resetFullCsvData();
			this.getWorldSteelRegions();
			this.setState(
				{
					groupType: oldRegions.length === 1 ? 'country' : 'region',
					selectedGrouping: oldRegions.length === 1 ? selectedGrouping : {},
					selected: oldRegions.length === 1 ? selected : 'regions',
					global: oldRegions.length === 1 ? false : true,
					compareCombine: '',
					collapse: 'daily',
					dateFrom: dateMax.clone().subtract(1, 'y'),
					dateTo: dateMax,
					reinit: true,
					wsdSelected: false
				},
				() => {
					this.filterData();
					this.setState({ reinit: false });
				}
			);
		}

		//once date range is set, fetch data
		if (
			global !== prevGlobal ||
			selectedGrouping[selected]?.length !==
				prevSelectedGrouping[selected]?.length
		) {
			this.resetFullCsvData();
		}

		if (isDownloadingAll && !prevIsDownloadingAll) {
			toastr.info('This might take a few minutes.');
		}

		if (
			(isInitDateRange === prevIsInitDateRange &&
				(prevDateFrom.format(config.apiDateFormat) !==
					dateFrom.format(config.apiDateFormat) ||
					prevDateTo.format(config.apiDateFormat) !==
						dateTo.format(config.apiDateFormat))) ||
			selected !== prevSelected ||
			selectedGrouping[selected]?.length !==
				prevSelectedGrouping[selected]?.length ||
			global !== prevGlobal ||
			collapse !== prevCollapse
		) {
			this.filterData();
		}

		if (
			wsdSelected &&
			((!worldSteelData.length &&
				prevWorldSteelData.length > worldSteelData.length) ||
				collapse !== prevCollapse ||
				prevDateFrom !== dateFrom ||
				prevDateTo !== dateTo ||
				global !== prevGlobal)
		) {
			this.handleWorldSteelData();
		}

		if (wsdSelected !== prevWsdSelected) {
			if (wsdSelected) {
				this.handleWorldSteelData();
			} else {
				this.setState({ worldSteelData: [] });
			}
		}

		if (
			Object.keys(steelSmelterProduction).length &&
			!Object.keys(prevSteelSmelterData).length &&
			(global || selectedGrouping[selected]?.length)
		) {
			if (
				selectedGrouping[selected]?.length &&
				steelSmelterProduction.singleIdx
			) {
				const remoteNames = steelSmelterProduction.singleIdx.map(
					({ name }) => name
				);
				const localNames = selectedGrouping[selected].map(({ name }) => name);
				const noDataNames = localNames.filter(
					(name) => !remoteNames.includes(name)
				);
				if (noDataNames.length) {
					toast.warn(
						`No Data for ${collapse} production for ${noDataNames.join(',')}`
					);
				}
			}
			this.setState({ isCollapsing: false });
		}
	}

	componentWillUnmount() {
		this.props.resetSteelSmelterProductionSubsites();
		this.props.resetLocations();
		this.props.resetGroups();
		this.props.resetLastUploadDate();
	}

	async getWorldSteelRegions() {
		const {
			match: {
				params: { sType }
			}
		} = this.props;

		if (this.userRole === 'superUser' || this.userRole === 'admin') {
			let worldSteelRegions = [];
			try {
				// const siteTypeId = siteTypes.find((st) => st?.name === sType)?.id || '';
				worldSteelRegions = await fetchWorldSteelRegions({
					subsiteType: sType
				});
			} catch (e) {
				console.log('Error while fetching World Steel Regions');
			}
			this.setState({ worldSteelRegions });
		}
	}

	updateDateHandling({ lastUploadDate }) {
		const maxDate = moment.utc(lastUploadDate);

		let downloadState = {};

		let dateState = {
			dateFrom: !this.isSnapshots
				? maxDate.clone().subtract(1, 'y').subtract(1, 'd')
				: maxDate.clone().subtract(1, 'y'),
			dateTo: !this.isSnapshots
				? maxDate.clone().subtract(1, 'd')
				: maxDate.clone(),
			dateMax: !this.isSnapshots
				? maxDate.clone().subtract(1, 'd')
				: maxDate.clone()
		};

		this.setState({
			...dateState,
			...downloadState,
			isInitDateRange: true
		});
	}

	//reset csv data for the full date range
	resetFullCsvData() {
		this.setState({ csvFull: { csvHeaders: [], csvData: [] } });
	}
	// Radio button click for combine/compare
	async onRadioBtnClick(rSelected) {
		this.setState({ ...rSelected });
	}

	// Global button click
	async onGlobalBtnClick() {
		const { global, selected, selectedGrouping } = this.state;
		let newState = {};
		//reset regions when switching to global, but only for current view
		if (!global) {
			const newSelectedGrouping = { ...selectedGrouping };
			newSelectedGrouping[selected] = [];
			newState = { ...newState, selectedGrouping: newSelectedGrouping };
		}

		//toggle global
		newState = {
			...newState,
			global: !global,
			compareCombine: '',
			worldSteelData: []
		};

		this.setState(newState);
	}

	// region buttons
	async onRegionBtnClick({ name, val }) {
		const { selected, selectedGrouping } = this.state;
		//existing regions
		const selectedIds = selectedGrouping[selected]?.map(({ id }) => id) || [];
		const oldSelectedCount = selectedIds.length;

		let selectedRegions = [];

		if (selectedIds.includes(val)) {
			selectedRegions = selectedGrouping[selected].filter(
				({ id }) => id !== val
			);
		} else {
			const selectedColours =
				selectedGrouping[selected]?.map(({ colour }) => colour) || [];
			const colour =
				selectedColours.length === 0
					? colourPalette[0]
					: colourPalette.filter((c) => !selectedColours.includes(c))[0];
			selectedRegions =
				selectedIds.length === 0
					? [{ id: val, name, colour }]
					: [...selectedGrouping[selected], { id: val, name, colour }];
		}
		const newSelectedGrouping = {
			...selectedGrouping,
			[selected]: selectedRegions
		};
		const newSelectedCount = newSelectedGrouping[selected].length;
		const global = newSelectedCount ? false : true;

		let newState = {
			selectedGrouping: newSelectedGrouping,
			global: global,
			worldSteelData: []
		};

		if (newSelectedCount <= 1) {
			newState = {
				...newState,
				compareCombine: ''
			};
		} else if (oldSelectedCount <= 1) {
			//regions count increased
			newState = { ...newState, compareCombine: 'combine', wsdSelected: false };
		}
		this.setState(newState);
	}

	handleMultipleSelections = async (vals) => {
		const { selected, selectedGrouping } = this.state;
		const oldSelectedIds =
			selectedGrouping[selected]?.map(({ id }) => id) || [];
		const newSelectedIds = vals.map(({ id }) => id);
		let newSelectedOptions = [];
		//removed
		if (oldSelectedIds.length > newSelectedIds.length) {
			newSelectedOptions = selectedGrouping[selected].filter(({ id }) =>
				newSelectedIds.includes(id)
			);
		} else {
			//one option added
			const newAdded = vals.filter(({ id }) => !oldSelectedIds.includes(id));
			const { id, name } = [...newAdded][0];
			const selectedColours =
				selectedGrouping[selected]?.map(({ colour }) => colour) || [];
			const colour =
				selectedColours.length === 0
					? colourPalette[0]
					: colourPalette.filter((c) => !selectedColours.includes(c))[0];
			newSelectedOptions =
				oldSelectedIds.length === 0
					? [{ id, name, colour }]
					: [...selectedGrouping[selected], { id, name, colour }];
		}

		const newSelectedGrouping = {
			...selectedGrouping,
			[selected]: newSelectedOptions
		};
		const newSelectedCount = newSelectedGrouping[selected].length;
		const global = newSelectedCount ? false : true;

		let newState = {
			selectedGrouping: newSelectedGrouping,
			global: global,
			worldSteelData: []
		};
		if (newSelectedCount <= 1) {
			newState = { ...newState, compareCombine: '' };
		} else if (oldSelectedIds.length <= 1) {
			//options count increased
			newState = { ...newState, compareCombine: 'combine', wsdSelected: false };
		}
		this.setState(newState);
	};

	// Region toggling for the select all / none button
	async onToggleAllRegions() {
		const { selectedGrouping, compareCombine } = this.state;
		const { locations } = this.props;
		const { regions } = locations;

		if (!selectedGrouping.hasOwnProperty(['regions'])) {
			selectedGrouping['regions'] = [];
		}

		let selectedRegions = [];
		if (selectedGrouping['regions'].length < regions.length) {
			const newRegions = regions.filter(
				({ id }) =>
					!selectedGrouping['regions'].map(({ id }) => id).includes(id)
			);
			const selectedColours =
				selectedGrouping['regions']?.map(({ colour }) => colour) || [];
			const newColours = colourPalette.filter(
				(c) => !selectedColours.includes(c)
			);
			newRegions.forEach((r, i) => (r.colour = newColours[i]));
			selectedRegions = [...selectedGrouping['regions'], ...newRegions];
		}
		const global = selectedRegions.length === 0;
		const newSelectedGrouping = {
			...selectedGrouping,
			regions: selectedRegions
		};
		const newCompareCombine =
			selectedGrouping['regions'].length > 1 ? compareCombine : 'combine';

		await this.setState({
			selectedGrouping: newSelectedGrouping,
			compareCombine: newSelectedGrouping['regions'].length
				? newCompareCombine
				: '',
			global,
			worldSteelData: [],
			wsdSelected: false
		});
	}

	// Handle date range selection event
	async onDateRangeSelect({ startDate, endDate, selectedGrouping, selected }) {
		//startDate, endDate - moment objects

		this.setState({
			dateFrom: startDate,
			dateTo: endDate,
			shortDateRange: endDate.diff(startDate, 'days') < config.dateRangeDayLimit
		});
	}

	onInputSelect = async ({ target: { value, name } }) => {
		const { selectedGrouping } = this.state;

		if (name === 'groupType') {
			let filtering = '';
			if (value === 'region') {
				filtering = 'regions';
			} else if (value === 'country') {
				filtering = 'countries';
			} else {
				filtering = value;
			}

			const global = selectedGrouping[filtering]?.length ? false : true;
			this.setState({
				selected: filtering,
				groupType: value,
				compareCombine: global ? '' : 'combine',
				global: global,
				collapse: 'daily',
				wsdSelected: false,
				worldSteelData: []
			});
		}
	};

	getRequestParams() {
		const { global, dateFrom, dateTo, selected, selectedGrouping, collapse } =
			this.state;

		const {
			match: {
				params: { sType }
			}
		} = this.props;

		if (global) {
			return {
				grouping: '',
				subsiteType: sType,
				ids: '',
				collapse,
				dateFrom: dateFrom.format(config.apiDateFormat),
				dateTo: dateTo.format(config.apiDateFormat)
			};
		} else if (selectedGrouping[selected]?.length > 0) {
			return {
				grouping:
					selected === 'regions' || selected === 'countries'
						? selected
						: 'groups',
				ids: selectedGrouping[selected]?.map(({ id }) => id).join(',') || '',
				subsiteType: sType,
				collapse,
				dateFrom: dateFrom.format(config.apiDateFormat),
				dateTo: dateTo.format(config.apiDateFormat)
			};
		}
	}

	filterData() {
		this.props.resetSteelSmelterProductionSubsites();
		const params = this.getRequestParams();
		if (params) {
			this.props.fetchSteelSmelterProductionSubsites(params);
		}
	}

	getChartType() {
		const { dateFrom, dateTo, showDispersion } = this.state;
		const days = dateTo.diff(dateFrom, 'd');
		const type = showDispersion ? 'a' : 'f';
		let size = '';

		if (days <= 50) {
			size = 'sm';
		} else if (days > 50 && days <= 100) {
			size = 'md';
		} else if (days > 100 && days <= 200) {
			size = 'lg';
		} else if (days > 200) {
			size = 'xl';
		} else if (days > 300) {
			size = 'xxl';
		}
		return { size, type, isSnapshots: this.isSnapshots };
	}

	// Build the card header element for display
	buildCardHeader() {
		const {
			compareCombine,
			global,
			groupType,
			collapse,
			selectedGrouping,
			selected
		} = this.state;

		const groupName = groupType.substring(6);
		let selection = groupType;
		if (groupType.startsWith('group_')) {
			selection = groupName;
		}

		let selections = 'groups';
		if (groupType === 'region') {
			selections = 'regions';
		} else if (groupType === 'country') {
			selections = 'countries';
		} else if (groupType.startsWith('group_')) {
			selections = `${groupName} ${selections}`;
		}

		const collapseTitle = collapse.charAt(0).toUpperCase() + collapse.slice(1);

		let title = '';
		if (global) {
			title = `${collapseTitle} Production: ${config.globalRegion.title}`;
		} else if (
			compareCombine === 'combine' &&
			selectedGrouping[selected]?.length > 1
		) {
			title = `${collapseTitle} Production: Combine multiple ${selections}`;
		} else if (
			compareCombine === 'compare' &&
			selectedGrouping[selected]?.length > 1
		) {
			title = `${collapseTitle} Production: Compare multiple ${selections}`;
		} else {
			title = `${collapseTitle} Production: Single ${selection}`;
		}
		return (
			<CardHeader>
				<i className="fa fa-line-chart" aria-hidden="true" /> {title}
			</CardHeader>
		);
	}

	buildRegionButton({ id, name, title }) {
		const { selectedGrouping, selected, isDownloadingAll } = this.state;
		return (
			<Fragment key={`region${name}`}>
				<Button
					id={`region_${id}`}
					outline
					size="sm"
					color="secondary"
					onClick={() => this.onRegionBtnClick({ name, val: id })}
					active={selectedGrouping[selected]?.map(({ id }) => id).includes(id)}
					disabled={isDownloadingAll}
					aria-label={title}
					className="light-active-border text-nowrap"
				>
					{title}
				</Button>
			</Fragment>
		);
	}
	mapKeysToPeriodOfTime = (prodArr, periodAndSuffix) => {
		let [period, suffix] = periodAndSuffix.split('_');
		suffix = suffix ? `_${suffix}` : '';
		const keyMapper = { daily: 'd', weekly: 'w', monthly: 'm', annual: 'y' };

		const prefix = keyMapper[period];
		const isMapped = prodArr.some((record) =>
			record.hasOwnProperty(`${prefix}p${suffix}`)
		);
		if (!isMapped) {
			const mapped = prodArr.map(({ t, p }) => ({
				[`${prefix}t`]: this.getCsvTimeComponent(t, period),
				[`${prefix}p${suffix}`]: p
			}));
			return mapped;
		}
		return prodArr;
	};

	handleAllProductionDataDownload = async () => {
		const {
			dateMin,
			dateMax,
			csvFull: { csvData }
		} = this.state;
		if (csvData.length) {
			this.csvFullDownloadLink.current.link.click();
			return;
		}
		const params = this.getRequestParams();
		this.setState({ isDownloadingAll: true });
		const prodData = await fetchCsvSteelProductionDataSubsites({
			...params,
			dateFrom: dateMin,
			dateTo: dateMax
		});
		this.setState({ isDownloadingAll: false });
		const numberOfDiffKeysIteration = [
			...new Set(prodData.map((pd) => Object.keys(pd)[0]))
		].length;

		const mergedData = prodData.reduce((merged, curr, i) => {
			const key = Object.keys(curr)[0];

			const foundIndex = merged.findIndex(
				(item) => Object.keys(item)[0] === key
			);
			if (foundIndex >= 0) {
				const mergedCombinedIdx = [
					[
						...this.mapKeysToPeriodOfTime(
							merged[foundIndex][key]?.combinedIdx || [],
							key
						),
						...this.mapKeysToPeriodOfTime(curr[key]?.combinedIdx || [], key)
					]
				]
					.map((combine) =>
						key.startsWith('weekly') &&
						i > prodData.length - numberOfDiffKeysIteration - 1
							? getUniqueObjects(combine, 'wt')
							: combine
					)
					.flat();
				const mappedMergedSingleIdx = (
					merged[foundIndex][key]?.singleIdx || []
				).map(({ name, data }) => ({
					name,
					data: this.mapKeysToPeriodOfTime(data, key)
				}));
				const mappedCurrentSingleIdx = (curr[key]?.singleIdx || []).map(
					({ name, data }) => ({
						name,
						data: this.mapKeysToPeriodOfTime(data, key)
					})
				);

				const mergedSingleIdx = mergeSubArraysBasedOnKey(
					[...mappedMergedSingleIdx, ...mappedCurrentSingleIdx],
					'name'
				).map(({ name, data }) => ({
					name,
					data:
						key.startsWith('weekly') &&
						i > prodData.length - numberOfDiffKeysIteration - 1
							? getUniqueObjects(data, 'wt')
							: data
				}));

				merged[foundIndex][key] = {
					combinedIdx: mergedCombinedIdx,
					singleIdx: mergedSingleIdx
				};
			} else {
				merged = [...merged, { [key]: curr[key] }];
			}
			return merged;
		}, []);
		const combinedIdxs = mergedData
			.map((md) => Object.values(md).map(({ combinedIdx }) => combinedIdx))
			.flat();

		const zippedCombinedProduction = zipArrays(...combinedIdxs);

		const singleIdxs = groupByKey(
			mergedData
				.map((md) => Object.values(md).map(({ singleIdx }) => singleIdx))
				.flat()
				.flat(),
			'name'
		);
		const singleProdForZipping = Object.keys(singleIdxs).map((key) => ({
			name: key,
			singleIdx: Object.values(singleIdxs[key]).map(({ data }) =>
				Object.values(data)
			)
		}));

		const zippedSingleProduction = singleProdForZipping.map(
			({ name, singleIdx }) => ({ [name]: zipArrays(...singleIdx) })
		);
		this.setCsvHeadersAndData({
			combinedIdx: zippedCombinedProduction,
			singleIdx: zippedSingleProduction
		});
	};

	setCsvHeadersAndData({ combinedIdx = [], singleIdx = [] }) {
		const commonHeaders = [
			{ label: 'Date', key: 'dt' },
			{ label: 'Daily (kt)', key: 'dp' },
			{ label: 'Daily BF/BOF (kt)', key: 'dp_bfbof' },
			{ label: 'Daily EAF (kt)', key: 'dp_eaf' },
			{ label: 'Week', key: 'wt' },
			{ label: 'Weekly (kt)', key: 'wp' },
			{ label: 'Weekly BF/BOF (kt)', key: 'wp_bfbof' },
			{ label: 'Weekly EAF (kt)', key: 'wp_eaf' },
			{ label: 'Month', key: 'mt' },
			{ label: 'Monthly (kt)', key: 'mp' },
			{ label: 'Monthly BF/BOF (kt)', key: 'mp_bfbof' },
			{ label: 'Monthly EAF (kt)', key: 'mp_eaf' },
			{ label: 'Year', key: 'yt' },
			{ label: 'Annual (kt)', key: 'yp' },
			{ label: 'Annual BF/BOF (kt)', key: 'yp_bfbof' },
			{ label: 'Annual EAF (kt)', key: 'yp_eaf' }
		];
		const {
			match: {
				params: { sType }
			}
		} = this.props;
		let headers = [];
		//global
		if (combinedIdx.length && !singleIdx.length) {
			const keys = Object.keys(combinedIdx[0]);
			headers = commonHeaders
				.filter((header) => keys.some((key) => key === header.key))
				.map(({ label, key }) => {
					if (key !== 'dt' && key !== 'wt' && key !== 'mt' && key !== 'yt') {
						return { label: `Global ${label}`, key };
					}
					return { label, key };
				});
			this.setState(
				{ csvFull: { csvHeaders: headers, csvData: combinedIdx } },
				() => {
					// click the CSVLink component to trigger the CSV download
					this.csvFullDownloadLink.current.link.click();
					ReactGA.event({
						category: `steel_${sType}_production_data_all_download`,
						action: 'download',
						label: this.userSub
					});
					return true;
				}
			);
		}
		//single region/country/group
		else if (!combinedIdx.length && singleIdx.length) {
			const keys = Object.keys(Object.values(singleIdx[0])[0][0]);

			headers = commonHeaders
				.filter((header) => keys.some((key) => key === header.key))
				.map(({ label, key }) => {
					if (key !== 'dt' && key !== 'wt' && key !== 'mt' && key !== 'yt') {
						return { label: `${Object.keys(singleIdx[0])[0]} ${label}`, key };
					}
					return { label, key };
				});
			this.setState(
				{
					csvFull: {
						csvHeaders: headers,
						csvData: Object.values(singleIdx[0])[0]
					}
				},
				() => {
					// click the CSVLink component to trigger the CSV download
					this.csvFullDownloadLink.current.link.click();
					ReactGA.event({
						category: `steel_${sType}_production_data_all_download`,
						action: 'download',
						label: this.userSub
					});
					return true;
				}
			);
		}
		//multiple regions/countries/groups
		else if (combinedIdx.length && singleIdx.length) {
			const keys = Object.keys(combinedIdx[0]);

			let headers = commonHeaders
				.filter((header) => keys.some((key) => key === header.key))
				.map(({ label, key }) => {
					if (key !== 'dt' && key !== 'wt' && key !== 'mt' && key !== 'yt') {
						return { label: `Combined ${label}`, key };
					}
					return { label, key };
				});

			let csvData = combinedIdx;

			singleIdx.forEach((single, i) => {
				const singleHeaders = commonHeaders
					.filter((header) => keys.some((key) => key === header.key))
					.map(({ label, key }) => {
						if (key !== 'dt' && key !== 'wt' && key !== 'mt' && key !== 'yt') {
							return {
								label: `${Object.keys(singleIdx[i])[0]} ${label}`,
								key: `${key}_${i}`
							};
						}
						return { label, key: `${key}_${i}` };
					});
				headers = [...headers, ...singleHeaders];

				const dataForSingle = Object.values(single)[0].map((record) => {
					const values = Object.values(record);
					const keys = Object.keys(record);
					return keys.reduce(
						(o, k, j) => ({ ...o, [`${k}_${i}`]: values[j] }),
						{}
					);
				});
				csvData = mergeArrays(csvData, dataForSingle);
			});

			this.setState({ csvFull: { csvHeaders: headers, csvData } }, () => {
				// click the CSVLink component to trigger the CSV download
				this.csvFullDownloadLink.current.link.click();
				ReactGA.event({
					category: `steel_${sType}_production_data_all_download`,
					action: 'download',
					label: this.userSub
				});
				return true;
			});
		} else return;
	}

	buildCsvFullDownloadButton() {
		const {
			global,
			selected,
			selectedGrouping,
			canDownload,
			csvFull: { csvHeaders, csvData }
		} = this.state;

		const {
			match: {
				params: { sType }
			}
		} = this.props;
		let groupingTitle = '';
		if (global) {
			groupingTitle = 'global-';
		} else if (selectedGrouping[selected]?.length) {
			selectedGrouping[selected].forEach(({ name }) => {
				groupingTitle =
					groupingTitle + name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() + '-';
			});
		} else return;
		const fileTitle = `${groupingTitle}${sType}-production`;

		if ((global || selectedGrouping[selected]?.length) && canDownload) {
			return (
				<Fragment>
					<LoaderButton
						className="btn mb-3 mr-1 btn-sm"
						color="success"
						disabled={false}
						type="button"
						isLoading={this.state.isDownloadingAll}
						onClick={this.handleAllProductionDataDownload}
						text="Download all data"
						loadingText="Downloading"
						icon={
							!this.state.isDownloadingAll && (
								<i className="fa fa-download mr-2" aria-hidden="true" />
							)
						}
					/>
					<CSVLink
						data={csvData}
						filename={fileTitle}
						headers={csvHeaders}
						className="hidden"
						ref={this.csvFullDownloadLink}
						target="_blank"
					/>
				</Fragment>
			);
		}

		return (
			<Button
				id="buttonAllDownload"
				color="success"
				disabled
				className="mb-3 pull-right"
				size="sm"
			>
				<i className="fa fa-download mr-2" aria-hidden="true" /> Download all
				data
			</Button>
		);
	}

	buildCsvDownloadButton() {
		const { global, shortDateRange, selected, selectedGrouping, canDownload } =
			this.state;
		const {
			steelSmelterProduction,
			match: {
				params: { sType }
			}
		} = this.props;

		if (
			(global || selectedGrouping[selected]?.length) &&
			!shortDateRange &&
			steelSmelterProduction.length !== 0 &&
			canDownload
		) {
			let { csvHeaders, csvData, csvFileName } = this.getCsvDataFromProps();

			return (
				<CSVLink
					id="buttonDownload"
					filename={csvFileName}
					headers={csvHeaders}
					data={csvData}
					className="btn btn-success mb-3 btn-sm"
					onClick={() => {
						ReactGA.event({
							category: `steel_${sType}_production_current_data_download`,
							action: 'download',
							label: this.userSub
						});
						return true;
					}}
				>
					<i className="fa fa-download mr-2" aria-hidden="true" /> Download this
					data
				</CSVLink>
			);
		}

		return (
			<Button
				id="buttonDownload"
				color="success"
				disabled
				className="mb-3"
				size="sm"
			>
				<i className="fa fa-download mr-2" aria-hidden="true" /> Download this
				data
			</Button>
		);
	}

	resetCollapseButton = (newCollapse) => {
		const { selectedGrouping, selected, compareCombine, collapse } = this.state;
		if (newCollapse === collapse) {
			return;
		}
		let wsdSelected = this.state.wsdSelected;

		if (newCollapse === 'daily' || newCollapse === 'weekly') {
			wsdSelected = false;
		}

		this.setState({
			collapse: newCollapse,
			isCollapsing: true,
			compareCombine:
				selectedGrouping[selected]?.length > 1 ? compareCombine : '',
			wsdSelected
		});
	};

	// Build the filter form
	buildFilterForm() {
		const { locations, groups, lastUploadDate } = this.props;
		const {
			dateMin,
			dateMax,
			dateFrom,
			dateTo,
			compareCombine,
			global,
			groupType,
			// regions,
			selectedGrouping,
			selected,
			collapse,
			isCollapsing,
			isDownloadingAll,
			wsdSelected,
			worldSteelRegions
		} = this.state;
		const isSnapshots = this.isSnapshots;
		const groupName = groupType.substring(6);

		const { regions, countries } = locations;
		const displayedRegions =
			worldSteelRegions.length > regions.length &&
			(collapse === 'monthly' || collapse === 'annual')
				? worldSteelRegions
				: regions;
		const sortedRegions = displayedRegions.sort((r1, r2) =>
			r1.name > r2.name ? 1 : -1
		);
		const disableDailyWeekly =
			selected === 'regions' && selectedGrouping[selected]?.length
				? !regions.some(({ id }) =>
						selectedGrouping[selected].map(({ id }) => id).includes(id)
				  )
				: false;
		const regionToggleHidden = displayedRegions.length < 2;
		const regionAllCopy =
			selectedGrouping['regions']?.length === displayedRegions.length
				? 'None'
				: 'All';
		return (
			<Form>
				<Container fluid className="px-0 container-width">
					<Row noGutters>
						<Col xs="12" sm="12">
							<Label for="groupType" className="mb-2 mr-3 align-top">
								<span className="sr-only">Geographical filtering type</span>
								<Input
									id="groupType"
									name="groupType"
									type="select"
									bsSize="sm"
									className="pointered"
									value={groupType}
									onChange={this.onInputSelect}
									disabled={isDownloadingAll}
								>
									{regions.length !== 1 && (
										<option value="region">Region filtering</option>
									)}
									<option value="country">Country filtering</option>
									{Object.keys(groups)
										.filter((g) => g !== 'plant')
										.map((g) => (
											<option key={`group_${g}`} value={`group_${g}`}>
												{g.charAt(0).toUpperCase() + g.slice(1)} filtering
											</option>
										))}
								</Input>
							</Label>
							{regions.length > 1 && (
								<Button
									id="regionGlobal"
									outline
									size="sm"
									color="secondary"
									active={global}
									aria-label="global"
									className="mb-2 light-active-border"
									onClick={() => this.onGlobalBtnClick()}
									disabled={isDownloadingAll}
								>
									Global
								</Button>
							)}
							{groupType === 'region' && displayedRegions.length > 0 && (
								<ButtonGroup className="mb-2 ml-3">
									{sortedRegions.map(({ name, id }) => {
										return this.buildRegionButton({
											id,
											name,
											title: name
										});
									})}
								</ButtonGroup>
							)}
							{groupType === 'region' && (
								<Button
									id="regionAll"
									color="primary"
									size="sm"
									onClick={() => this.onToggleAllRegions()}
									className="mb-2 ml-1"
									style={{ width: '54px' }}
									disabled={global || isDownloadingAll}
									hidden={regionToggleHidden}
								>
									{regionAllCopy}
								</Button>
							)}
							{groupType.startsWith('group_') &&
								groups[groupName].length > 0 &&
								groups[groupName].length <= 12 && (
									<ButtonGroup className="mb-2 ml-3">
										{groups[groupName].map(({ name, id }) => {
											return this.buildRegionButton({
												id,
												name,
												title: name
											});
										})}
									</ButtonGroup>
								)}
							{groupType.startsWith('group_') &&
								groups[groupName].length > 12 && (
									<MultiSelect
										options={groups[groupName]}
										values={selectedGrouping[groupType]}
										limit={config.multipleSelectionLimit}
										disabled={isDownloadingAll}
										onMultipleSelection={this.handleMultipleSelections}
									/>
								)}
							{groupType === 'country' && (
								<MultiSelect
									options={countries}
									values={selectedGrouping.countries}
									limit={config.multipleSelectionLimit}
									disabled={isDownloadingAll}
									onMultipleSelection={this.handleMultipleSelections}
								/>
							)}
						</Col>
					</Row>
					<Row noGutters>
						<Col xs="12" sm="12">
							<DateRangePopover
								id="dateSelector"
								startDate={dateFrom}
								endDate={dateTo}
								minDate={dateMin}
								maxDate={dateMax}
								newestDate={lastUploadDate}
								shouldUpdateStatic={isCollapsing}
								isShortRange={collapse === 'annual'}
								disabled={isDownloadingAll}
								isSnapshots={this.isSnapshots}
								onDateRangeSelected={this.onDateRangeSelect.bind(this)}
							/>

							<ButtonGroup className="mb-3 mr-3 align-bottom">
								<Button
									outline
									size="sm"
									color="secondary"
									onClick={() => this.resetCollapseButton('daily')}
									active={collapse === 'daily' || isSnapshots}
									disabled={disableDailyWeekly || isDownloadingAll}
									aria-label="Click to display daily production"
								>
									Daily
								</Button>
								<Button
									outline
									size="sm"
									color="secondary"
									onClick={() => this.resetCollapseButton('weekly')}
									active={collapse === 'weekly'}
									disabled={
										disableDailyWeekly || isDownloadingAll || isSnapshots
									}
									aria-label="Click to display weekly production"
								>
									Weekly
								</Button>
								<Button
									outline
									size="sm"
									color="secondary"
									onClick={() => this.resetCollapseButton('monthly')}
									active={collapse === 'monthly'}
									disabled={isDownloadingAll || isSnapshots}
									aria-label="Click to display monthly production"
								>
									Monthly
								</Button>
								<Button
									outline
									size="sm"
									color="secondary"
									onClick={() => this.resetCollapseButton('annual')}
									active={collapse === 'annual'}
									disabled={isDownloadingAll || isSnapshots}
									aria-label="Click to display yearly production"
								>
									Annual
								</Button>
							</ButtonGroup>

							{this.state.worldSteelRegions.length !== 0 &&
								(this.userRole === 'superUser' ||
									this.userRole === 'admin') && (
									<Button
										className="mb-3 mr-3 align-bottom"
										outline
										size="sm"
										color="secondary"
										onClick={() =>
											this.setState({ wsdSelected: !this.state.wsdSelected })
										}
										active={wsdSelected}
										disabled={
											selectedGrouping[selected]?.length > 1 ||
											collapse === 'daily' ||
											collapse === 'weekly' ||
											isDownloadingAll
										}
										aria-label="Click to get World Steel Data"
									>
										World Steel Data
									</Button>
								)}

							<ButtonGroup className="mb-3 mr-3 align-bottom">
								<Button
									id="buttonCombine"
									outline
									size="sm"
									color="secondary"
									onClick={() =>
										this.onRadioBtnClick({ compareCombine: 'combine' })
									}
									active={compareCombine === 'combine'}
									disabled={!global && selectedGrouping[selected]?.length < 1}
									aria-label="Click to combine smelter data"
								>
									Combine
								</Button>
								<Button
									id="buttonCompare"
									outline
									size="sm"
									color="secondary"
									onClick={() =>
										this.onRadioBtnClick({ compareCombine: 'compare' })
									}
									active={compareCombine === 'compare'}
									disabled={global || selectedGrouping[selected]?.length <= 1}
									aria-label="Click to compare smelter data"
								>
									Compare
								</Button>
							</ButtonGroup>
							{this.state.canDownload && this.buildCsvFullDownloadButton()}
							{this.state.canDownload && this.buildCsvDownloadButton()}
						</Col>
					</Row>
				</Container>
			</Form>
		);
	}

	handleWorldSteelData = async () => {
		const { global, selectedGrouping, selected, collapse, dateFrom, dateTo } =
			this.state;
		const {
			match: {
				params: { sType }
			}
		} = this.props;

		let grouping = '';
		let id = '';
		if (!global && selectedGrouping[selected]?.length) {
			grouping =
				selected === 'regions' || selected === 'countries'
					? selected
					: 'groups';
			id = selectedGrouping[selected][0].id;
		}

		// const siteTypeId = siteTypes.find((st) => st?.name === sType)?.id || '';

		try {
			const data = await fetchWorldSteelSubsitesData({
				grouping,
				id,
				subsiteType: sType,
				collapse,
				dateFrom: dateFrom.format(config.apiDateFormat),
				dateTo: dateTo.format(config.apiDateFormat)
			});
			if (data.length === 0) {
				const selection = selectedGrouping[selected]
					? selectedGrouping[selected][0].name
					: 'Global';
				toastr.warning(
					`No ${collapse} World Steel Data found for ${selection} `
				);
			}
			this.setState({ worldSteelData: data });
		} catch (e) {
			console.log(e);
			const message = e?.response?.data?.message || e;
			toastr.error(`World Steel Data: ${message}`);
		}
	};

	getCsvTimeComponent(t, collapse = '') {
		const dateFormatting = collapse ? collapse : this.state.collapse;

		if (dateFormatting === 'weekly') {
			return `W${moment.utc(t, 'WW GGGG').format('WW GGGG')}`;
		} else if (dateFormatting === 'monthly') {
			return `${moment.utc(t, 'MMM YYYY').format('MMM YYYY')}`;
		} else if (dateFormatting === 'annual') {
			return `${moment.utc(t, 'YYYY').format('YYYY')}`;
		} else return moment.utc(t).format(config.dspDateFormat);
	}

	// Build csv data from the props for the download feature
	getCsvDataFromProps() {
		const {
			steelSmelterProduction: { singleIdx = [], combinedIdx = [] },
			match: {
				params: { sType }
			}
		} = this.props;
		let {
			dateFrom,
			dateTo,
			global,
			selectedGrouping,
			selected,
			collapse,
			worldSteelData
		} = this.state;

		let groupingTitle = '';
		if (global) {
			groupingTitle = 'global-';
		} else {
			selectedGrouping[selected].forEach(({ name }) => {
				groupingTitle =
					groupingTitle + name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() + '-';
			});
		}
		const fileTitle = `${groupingTitle}${collapse}-`;
		// Initialise our csv data
		let csvFileName = `${fileTitle}${sType}-production-${dateFrom.format(
			config.apiDateFormat
		)}-${dateTo.format(config.apiDateFormat)}.csv`;
		let timeLabel = 'Date';
		if (collapse === 'weekly') {
			timeLabel = 'Week';
		} else if (collapse === 'monthly') {
			timeLabel = 'Month';
		} else if (collapse === 'annual') {
			timeLabel = 'Year';
		}
		let csvHeaders = [{ label: timeLabel, key: 't' }];
		let csvData = [];

		if (global || selectedGrouping[selected]?.length === 1) {
			const data = global ? combinedIdx : singleIdx[0]?.data || [];
			const name = global ? 'Global' : singleIdx[0]?.name || '';
			data.forEach(({ t, p }, j) => {
				// Add the time column value to the csv data if this is the first region iteration
				// or if we've somehow got a frame error from the api data and the row is missing
				if (!csvData[j]) {
					csvData[j] = {
						t: this.getCsvTimeComponent(t)
					};
				}
				// Generate the region-based f and σ column names
				let namepkey = `${name} Production (kt)`;

				// Add the column names to the csv headers if this is the first data iteration
				if (j === 0) {
					csvHeaders = [
						...csvHeaders,
						...[
							{
								label: namepkey,
								key: namepkey
							}
						]
					];
				}

				// Set the p column on the csv data
				csvData[j][namepkey] = p;
			});

			if (worldSteelData.length) {
				worldSteelData.forEach(({ t, p }, m) => {
					// Add the time column value to the csv data if this is the first iteration
					// or if we've somehow got a frame error from the api data and the row is missing
					if (!csvData[m]) {
						csvData[m] = {
							t: this.getCsvTimeComponent(t)
						};
					}
					if (m === 0) {
						csvHeaders = [
							...csvHeaders,
							...[
								{
									label: 'World Steel Data (kt)',
									key: 'wsd'
								}
							]
						];
					}
					csvData[m]['wsd'] = p;
				});
			}
		} else {
			singleIdx.forEach(({ name, data }) => {
				data.forEach(({ t, p }, j) => {
					// Add the time column value to the csv data if this is the first region iteration
					// or if we've somehow got a frame error from the api data and the row is missing
					if (!csvData[j]) {
						csvData[j] = {
							t: this.getCsvTimeComponent(t)
						};
					}

					// Generate the region-based f and σ column names
					let namepkey = `${name} Production (kt)`;

					// Add the column names to the csv headers if this is the first data iteration
					if (j === 0) {
						csvHeaders = [
							...csvHeaders,
							...[
								{
									label: namepkey,
									key: namepkey
								}
							]
						];
					}

					// Set the p column on the csv data
					csvData[j][namepkey] = p;
				});
			});
			combinedIdx.forEach(({ t, p }, k) => {
				// Add the time column value to the csv data if this is the first iteration
				// or if we've somehow got a frame error from the api data and the row is missing
				if (!csvData[k]) {
					csvData[k] = {
						t: this.getCsvTimeComponent(t)
					};
				}

				// Generate the p column names
				let namepkey = global
					? `Global Production (kt)`
					: `Combined Production (kt)`;

				// Add the column names to the csv headers if this is the first data iteration
				if (k === 0) {
					csvHeaders = [
						...csvHeaders,
						...[
							{
								label: namepkey,
								key: namepkey
							}
						]
					];
				}
				// Set the f column on the csv data
				csvData[k][namepkey] = p;
			});
		}
		return {
			csvFileName,
			csvHeaders,
			csvData
		};
	}

	buildRegionSelectError() {
		return (
			<Alert color="warning" className="p-2 mb-1">
				Please select at least one region.
			</Alert>
		);
	}

	buildShortDateRangeError() {
		return (
			<Alert color="warning" className="p-2 mb-1">
				Please select a date range of at least 10 days.
			</Alert>
		);
	}

	buildLoadingMessage() {
		return (
			<Fragment>
				<div className="h6 mb-3">Chart data loading...</div>
				<Progress animated value="100" />
			</Fragment>
		);
	}

	getSelectedDetail(arr = []) {
		const { global, selected, selectedGrouping, wsdSelected, worldSteelData } =
			this.state;

		if (wsdSelected && worldSteelData.length) {
			return arr.map((a) => ({
				key: a.replace(/[^a-zA-Z0-9]/g, '').toLowerCase(),
				title: a,
				name: a,
				colour:
					a.substr(a.length - 4) === '_WSD'
						? '#AA33FF'
						: global
						? config.globalRegion.colour
						: selectedGrouping[selected].find(({ name }) => name === a)
								?.colour || ''
			}));
		} else if (arr.length === 1 && global) {
			return [{ key: 'global', ...config.globalRegion }];
		} else if (arr.length === 1 && arr[0] === 'combine') {
			return [{ key: 'combine', ...config.combinedRegion }];
		} else if (arr.length >= 1) {
			return arr.map((a) => ({
				key: a.replace(/[^a-zA-Z0-9]/g, '').toLowerCase(),
				title: a,
				name: a,
				colour:
					selectedGrouping[selected]?.find(({ name }) => name === a)?.colour ||
					''
			}));
		}
	}

	getChartData(dataArr) {
		const details = this.getSelectedDetail(dataArr.map(({ name }) => name));

		// Build the datasets by iterating the allRegions array and reducing it with the smelterData compare field,
		// so that the original order of the regions is maintained
		return dataArr.map(({ name, data }) => {
			const { collapse, dateMax, global, compareCombine } = this.state;
			const detail = details.find(
				(d) => d.key === name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()
			);

			//filled point for combine
			const fCombinePointBackgroundColour =
				name === 'combine' || (global && compareCombine !== 'compare')
					? { pointBackgroundColor: detail.colour }
					: {};

			//fStyle will be added to existing active production style in configuration
			const fStyle = {
				label: detail.name,
				borderColor: detail.colour,
				...fCombinePointBackgroundColour
			};

			const addStyle = fStyle;

			return {
				data: data
					.map(({ t, p }) => ({
						t: ['weekly', 'monthly', 'annual'].includes(collapse)
							? t
							: moment.utc(t).format(config.dspDateFormat),
						y: p
					}))
					.filter(({ t }, i) =>
						// exclude the last point
						{
							if (
								data.length > 2 &&
								i === data.length - 1 &&
								((collapse === 'weekly' &&
									moment(dateMax).isBefore(
										moment
											.utc(t, 'WW GGGG')
											.endOf('isoWeek')
											.format(config.dspDateFormat)
									)) ||
									(collapse === 'monthly' &&
										dateMax.isBefore(
											moment
												.utc(t, 'MMM YYYY')
												.endOf('month')
												.format(config.dspDateFormat)
										)) ||
									(collapse === 'annual' &&
										moment(dateMax).isBefore(
											moment
												.utc(t, 'YYYY')
												.endOf('year')
												.format(config.dspDateFormat)
										)))
							) {
								return false;
							}
							return true;
						}
					),
				...genChartStyle(this.getChartType()),
				...addStyle
			};
		});
	}

	// Individual data lines shown for multiple regions
	buildLineChart() {
		const { steelSmelterProduction } = this.props;

		const {
			compareCombine,
			global,
			selected,
			selectedGrouping,
			collapse,
			worldSteelData,
			wsdSelected
		} = this.state;

		const isCombined =
			compareCombine === 'combine' &&
			!global &&
			selectedGrouping[selected]?.length > 1;

		let apiData = '';

		if (
			(global || selectedGrouping[selected]?.length === 1) &&
			worldSteelData.length
		) {
			apiData = [
				{
					name: global
						? 'Global_WSD'
						: `${selectedGrouping[selected][0].name}_WSD`,
					data: worldSteelData
				}
			];
			if (steelSmelterProduction) {
				const prodData = global
					? [{ name: 'Global', data: steelSmelterProduction.combinedIdx }]
					: steelSmelterProduction.singleIdx;

				apiData = wsdSelected ? [...prodData, ...apiData] : prodData;
			}
		} else if (isCombined) {
			apiData = [
				{
					name: 'combine',
					data:
						steelSmelterProduction.combinedIdx ||
						(steelSmelterProduction?.singleIdx &&
							steelSmelterProduction.singleIdx[0]?.data) ||
						''
				}
			];
		} else if (global) {
			apiData = [
				{
					name: 'Global',
					data: steelSmelterProduction.combinedIdx
				}
			];
		} else {
			apiData =
				steelSmelterProduction.singleIdx ||
				selectedGrouping[selected]?.map(({ name }) => ({ name, data: '' }));
		}

		//compare data length gives info whether API response is received, combined data length doesn't contain that information
		// if (!apiData || apiData.every(({ data }) => data === '' || isCollapsing)) {
		// 	return this.buildLoadingMessage();
		// }
		if (!steelSmelterProduction) return this.buildLoadingMessage();

		//check this condition
		if (apiData.every(({ data }) => !data || data.length === 0)) {
			return;
		}

		const datasets = this.getChartData(apiData);
		const maxLength = Math.max(...datasets.map(({ data }) => data.length));
		const m = datasets.findIndex((d) => d.data.length === maxLength);
		const labels = ['weekly', 'monthly', 'annual'].includes(collapse)
			? datasets[m].data.map(({ t }) => t)
			: [];
		const maxDataset = datasets[m];
		datasets.length > 1 &&
			datasets.forEach((ds) => {
				const newData = [];
				if (ds.data.length !== maxLength) {
					maxDataset.data.forEach(({ t }) => {
						if (!ds.data.map(({ t }) => t).includes(t)) {
							newData.push({ t });
						} else {
							const existProd = ds.data.find((d) => d.t === t).y;
							newData.push({ t, y: existProd });
						}
					});
					ds.data = newData;
				}
			});

		let options = {};
		if (collapse === 'weekly') {
			options = pwChartOptions;
		} else if (collapse === 'monthly') {
			options = pmChartOptions;
		} else if (collapse === 'annual') {
			options = pyChartOptions;
		} else {
			options = pChartOptions;
		}
		return (
			<Card className="mb-3">
				{this.buildCardHeader()}
				<CardBody>
					<Container
						fluid
						className="pl-0 container-width"
						style={{ paddingRight: '21px' }}
					>
						<Row noGutters className="mt-0">
							<Col xs="12" sm="12">
								<Line data={{ labels, datasets }} options={options} redraw />
							</Col>
						</Row>
					</Container>
				</CardBody>
			</Card>
		);
	}

	render() {
		let { global, shortDateRange, selectedGrouping, selected } = this.state;
		const {
			match: {
				params: { sType }
			}
		} = this.props;

		const subsiteTitle = capitaliseAll(getAlphaNumeric(sType));

		return (
			<DocumentTitle
				title={`GAINS | Steel  ${subsiteTitle} Smelter Production`}
			>
				<div className="content-wrapper">
					<Container fluid>
						{/* Help, crumbs and the filter form */}
						{/* <div className="pull-right">{smeltersInlineHelpContent}</div> */}
						<Crumbs
							type="steel"
							path={[{ title: `${subsiteTitle} Smelter Production` }]}
						/>
						{this.buildFilterForm()}

						{/* Error messages */}
						{shortDateRange && this.buildShortDateRangeError()}
						{!shortDateRange &&
							!global &&
							!selectedGrouping[selected]?.length &&
							this.buildRegionSelectError()}

						{/* Different chart types */}
						{!shortDateRange &&
							(selectedGrouping[selected]?.length > 0 || global) &&
							this.buildLineChart()}
					</Container>
					<ToastContainer theme="colored" autoClose={3000} />
				</div>
			</DocumentTitle>
		);
	}
}

const mapStateToProps = ({
	lastUploadDate,
	locations,
	groups,
	siteTypes,
	steelSmelterProductionSubsites
}) => {
	const { regions } = locations;
	const filteredGroups =
		regions.length > 1
			? groups
			: Object.keys(groups)
					.filter((objKey) => objKey !== 'Custom region')
					.reduce((newObj, key) => {
						newObj[key] = groups[key];
						return newObj;
					}, {});
	return {
		lastUploadDate,
		locations,
		groups: filteredGroups,
		siteTypes,
		steelSmelterProduction: steelSmelterProductionSubsites.data
	};
};

const mapDispatchToProps = (dispatch) => ({
	fetchLastUploadDate: (filterData) =>
		dispatch(fetchLastUploadDate(filterData)),
	resetLastUploadDate: () => dispatch(resetLastUploadDate()),
	fetchSteelSmelterProductionSubsites: (filterData) =>
		dispatch(fetchSteelSmelterProductionSubsites(filterData)),
	resetSteelSmelterProductionSubsites: () =>
		dispatch(resetSteelSmelterProductionSubsites()),

	fetchLocations: (filterData) => dispatch(fetchLocations(filterData)),
	resetLocations: () => dispatch(resetLocations()),

	fetchGroups: (filterData) => dispatch(fetchGroups(filterData)),
	resetGroups: () => dispatch(resetGroups()),

	fetchSiteTypes: () => dispatch(fetchSiteTypes())
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(SteelSmeltersProductionSubsites));
