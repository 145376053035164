import moment from 'moment';
import colours from '../../../_colours.scss';

const colourPalette = [
	'#4053d3',
	'#ddb310',
	'#b51d14',
	'#00beff',
	'#fb49b0',
	'#00b25d',
	'#cacaca'
];

//style for Inactive Capacity index
const fChartStyle = {
	fill: false,
	borderWidth: 1,
	borderJoinStyle: 'round',
	pointStyle: 'circle',
	pointBackgroundColor: '#fff',
	pointBorderWidth: 1,
	pointHoverRadius: 2,
	pointHoverBorderColor: 'rgba(86,86,86,1)',
	pointHoverBorderWidth: 1,
	pointRadius: 2,
	pointHitRadius: 5
};

const annotationsStem = {
	events: ['mouseover', 'mouseout'],
	annotations: []
};

const annotationStem = {
	drawTime: 'afterDatasetsDraw',
	type: 'line',
	mode: 'vertical',
	scaleID: 'x-axis-0',
	borderColor: colours.earthiVividGreen,
	borderWidth: 2,
	label: {
		backgroundColor: colours.earthiGrassGreen,
		fontSize: 12,
		fontStyle: 'normal',
		fontColor: colours.earthiWhite,
		cornerRadius: 3,
		enabled: false,
		position: 'bottom',
		yAdjust: 20
	},
	onMouseover: function (e) {
		const element = this;
		for (let k in element.chartInstance.annotation.elements) {
			let anno = element.chartInstance.annotation.elements[k];
			if (anno.id !== this.id) {
				anno.options.borderColor = 'rgba(117, 182, 38, 0.3)';
			}
		}
		element.options.borderColor = colours.earthiGrassGreen;
		element.options.borderWidth = 3;
		element.options.label.enabled = true;
		element.chartInstance.update();
		element.chartInstance.chart.canvas.style.cursor = 'pointer';
	},
	onMouseout: function (e) {
		const element = this;
		setTimeout(() => {
			for (let k in element.chartInstance.annotation.elements) {
				let anno = element.chartInstance.annotation.elements[k];
				if (anno.id !== this.id) {
					anno.options.borderColor = colours.earthiVividGreen;
				}
			}
			element.options.borderColor = colours.earthiVividGreen;
			element.options.borderWidth = 2;
			element.options.label.enabled = false;
			element.chartInstance.update();
		}, 200);
		element.chartInstance.chart.canvas.style.cursor = 'initial';
	}
};

//options for Inactive Capacity index
const fChartOptions = {
	responsive: true,
	maintainAspectRatio: true,
	animation: {
		duration: 0
	},
	scales: {
		xAxes: [
			{
				type: 'time',
				distribution: 'linear',
				scaleLabel: {
					display: true
					// labelString: 'Time'
				},
				time: {
					displayFormats: {
						hour: 'D MMM YY',
						day: 'D MMM YY',
						week: 'D MMM YY',
						month: 'D MMM YY',
						quarter: 'D MMM YY',
						year: 'YYYY'
					}
				}
			}
		],
		yAxes: [
			{
				distribution: 'linear',
				id: 'inactive-capacity',
				position: 'left',
				scaleLabel: {
					display: true,
					labelString: 'Inactive Capacity'
				},
				ticks: {
					beginAtZero: false
					// min: 0
				}
			}
		]
	},
	title: {
		display: false
	},
	legend: {
		labels: {
			usePointStyle: true,
			padding: 20,
			filter: (item) => {
				return (
					!item.text.includes('Max') &&
					!item.text.includes('Min') &&
					!item.text.includes('threshold')
				);
			}
		}
	}
};

//capacity Chart options
const cChartOptions = {
	...fChartOptions,
	scales: {
		...fChartOptions.scales,
		yAxes: fChartOptions.scales.yAxes.map((ya) => ({
			...ya,
			id: 'capacity',
			scaleLabel: {
				display: true,
				labelString: 'Capacity (kt)'
			}
		}))
	}
};

//production Chart options
const pChartOptions = {
	...fChartOptions,
	scales: {
		...fChartOptions.scales,
		yAxes: fChartOptions.scales.yAxes.map((ya) => ({
			...ya,
			id: 'production',
			scaleLabel: {
				display: true,
				labelString: 'Production (kt)'
			}
		}))
	}
};

//production weekly Chart options
const pwChartOptions = {
	type: 'line',
	responsive: true,
	maintainAspectRatio: true,
	animation: {
		duration: 0
	},
	tooltips: {
		mode: 'label',
		callbacks: {
			title: function (tooltipItem) {
				return `W${moment
					.utc(tooltipItem[0].xLabel, 'WW GGGG')
					.format('WW')} ${moment
					.utc(tooltipItem[0].xLabel, 'WW GGGG')
					.startOf('isoWeek')
					.format('Do MMM')} - ${moment
					.utc(tooltipItem[0].xLabel, 'WW GGGG')
					.endOf('isoWeek')
					.format('Do MMM')}`;
				// .format('WW GGGG')}`;
			},
			label: function (tooltipItem) {
				return `Production: ${tooltipItem.yLabel}`;
			}
		}
	},
	elements: {
		line: {
			tension: 0
		}
	},
	scales: {
		xAxes: [
			{
				// distribution: 'linear',
				scaleLabel: {
					display: true
				},
				ticks: {
					callback: function (val) {
						return `W${moment.utc(val, 'WW GGGG').format('WW GGGG')}`;
					}
				}
			}
		],
		yAxes: [
			{
				distribution: 'linear',
				id: 'weekly-production',
				position: 'left',
				scaleLabel: {
					display: true,
					labelString: 'Production (kt)'
				},
				ticks: {
					beginAtZero: false
					// min: 0
				}
			}
		]
	},
	title: {
		display: false
	},
	legend: {
		labels: {
			usePointStyle: true,
			padding: 20
		}
	}
};

//production monthly Chart options
const pmChartOptions = {
	...pwChartOptions,
	tooltips: {
		mode: 'label',
		callbacks: {
			title: function (tooltipItem) {
				return `${moment
					.utc(tooltipItem[0].xLabel, 'MMM YYYY')
					.format('MMM YYYY')}`;
			},
			label: function (tooltipItem) {
				return `Production: ${tooltipItem.yLabel}`;
			}
		}
	},
	scales: {
		...pwChartOptions.scales,
		xAxes: pwChartOptions.scales.xAxes.map((xa) => ({
			...xa,
			ticks: {
				...xa.ticks,
				callback: (val) => {
					return moment.utc(val, 'MMM YYYY').format('MMM YYYY');
				}
			}
		}))
	}
};

//production annual Chart options
const pyChartOptions = {
	...pmChartOptions,
	tooltips: {
		mode: 'label',
		callbacks: {
			title: function (tooltipItem) {
				return `Y${moment.utc(tooltipItem[0].xLabel, 'YYYY').format('YYYY')}`;
			},
			label: function (tooltipItem) {
				return `Production: ${tooltipItem.yLabel}`;
			}
		}
	},

	scales: {
		...pwChartOptions.scales,
		xAxes: pwChartOptions.scales.xAxes.map((xa) => ({
			...xa,
			ticks: {
				...xa.ticks,
				callback: (val) => {
					return moment.utc(val, 'YYYY').format('YYYY');
				}
			}
		}))
	}
};

//emission Chart options
const eChartOptions = {
	...fChartOptions,
	scales: {
		...fChartOptions.scales,
		yAxes: fChartOptions.scales.yAxes.map((ya) => ({
			...ya,
			id: 'co2-emissions',
			scaleLabel: {
				display: true,
				labelString: 'CO2 Emissions (kt)'
			}
		}))
	}
};

//production weekly Chart options
const ewChartOptions = {
	type: 'line',
	responsive: true,
	maintainAspectRatio: true,
	animation: {
		duration: 0
	},
	tooltips: {
		mode: 'label',
		callbacks: {
			title: function (tooltipItem) {
				return `W${moment
					.utc(tooltipItem[0].xLabel, 'WW GGGG')
					.format('WW')} ${moment
					.utc(tooltipItem[0].xLabel, 'WW GGGG')
					.startOf('isoWeek')
					.format('Do MMM')} - ${moment
					.utc(tooltipItem[0].xLabel, 'WW GGGG')
					.endOf('isoWeek')
					.format('Do MMM')}`;
				// .format('WW GGGG')}`;
			},
			label: function (tooltipItem) {
				return `CO2 Emissions: ${tooltipItem.yLabel}`;
			}
		}
	},
	elements: {
		line: {
			tension: 0
		}
	},
	scales: {
		xAxes: [
			{
				// distribution: 'linear',
				scaleLabel: {
					display: true
				},
				ticks: {
					callback: function (val) {
						return `W${moment.utc(val, 'WW GGGG').format('WW GGGG')}`;
					}
				}
			}
		],
		yAxes: [
			{
				distribution: 'linear',
				id: 'weekly-co2-emissions',
				position: 'left',
				scaleLabel: {
					display: true,
					labelString: 'CO2 Emissions (kt)'
				},
				ticks: {
					beginAtZero: false
					// min: 0
				}
			}
		]
	},
	title: {
		display: false
	},
	legend: {
		labels: {
			usePointStyle: true,
			padding: 20
		}
	}
};

//production monthly Chart options
const emChartOptions = {
	...ewChartOptions,
	tooltips: {
		mode: 'label',
		callbacks: {
			title: function (tooltipItem) {
				return `${moment
					.utc(tooltipItem[0].xLabel, 'MMM YYYY')
					.format('MMM YYYY')}`;
			},
			label: function (tooltipItem) {
				return `CO2 Emissions: ${tooltipItem.yLabel}`;
			}
		}
	},
	scales: {
		...ewChartOptions.scales,
		xAxes: ewChartOptions.scales.xAxes.map((xa) => ({
			...xa,
			ticks: {
				...xa.ticks,
				callback: (val) => {
					return moment.utc(val, 'MMM YYYY').format('MMM YYYY');
				}
			}
		}))
	}
};

//production annual Chart options
const eyChartOptions = {
	...emChartOptions,
	tooltips: {
		mode: 'label',
		callbacks: {
			title: function (tooltipItem) {
				return `Y${moment.utc(tooltipItem[0].xLabel, 'YYYY').format('YYYY')}`;
			},
			label: function (tooltipItem) {
				return `CO2 Emissions: ${tooltipItem.yLabel}`;
			}
		}
	},

	scales: {
		...ewChartOptions.scales,
		xAxes: ewChartOptions.scales.xAxes.map((xa) => ({
			...xa,
			ticks: {
				...xa.ticks,
				callback: (val) => {
					return moment.utc(val, 'YYYY').format('YYYY');
				}
			}
		}))
	}
};

//style for Activity Dispersion Index
const aChartStyle = {
	...fChartStyle,
	fill: '-1',
	label: 'Activity Dispersion Index',
	borderColor: colours.earthiGrassGreen,
	pointBackgroundColor: colours.earthiGrassGreen
};

const aChartOptions = {
	...fChartOptions,
	//exclude tooltips from 50% line
	tooltips: {
		filter: function (tooltipItem) {
			return tooltipItem.datasetIndex > 0;
		}
	},
	scales: {
		...fChartOptions.scales,
		yAxes: [
			{
				distribution: 'linear',
				id: 'activity-dispersion',
				position: 'left',
				scaleLabel: {
					display: true,
					labelString: 'Activity Dispersion'
				},
				ticks: {
					// beginAtZero: false,
					min: 0,
					max: 100
				}
			}
		]
	}
};

//style for horizontal line at 50% of activity dispersion plot
const aThresholdStyle = {
	fill: false,
	lineTension: 0.4,
	borderWidth: 1,
	borderColor: colours.savantCopper,
	label: 'threshold',
	pointRadius: 0,
	pointHitRadius: 0
};

const genChartStyle = function ({ size, type, isSnapshots = false }) {
	const style = type === 'f' ? fChartStyle : aChartStyle;
	if (size === 'sm') {
		return {
			...style,
			...{
				pointHoverRadius: 3.5,
				pointRadius: 3.5,
				pointHitRadius: 3.5
			}
		};
	} else if (size === 'md') {
		return {
			...style,
			...{
				pointHoverRadius: 2.5,
				pointRadius: 2.5,
				pointHitRadius: 2.5
			}
		};
	} else if (size === 'lg' && isSnapshots) {
		return {
			...style,
			...{
				pointHoverRadius: !isSnapshots ? 1.5 : 2.5,
				pointRadius: !isSnapshots ? 1.5 : 2.5,
				pointHitRadius: !isSnapshots ? 1.5 : 2.5
			}
		};
	} else if (size === 'xl') {
		return {
			...style,
			...{
				pointHoverRadius: !isSnapshots ? 1 : 2,
				pointRadius: !isSnapshots ? 1 : 2,
				pointHitRadius: !isSnapshots ? 1 : 2
			}
		};
	} else if (size === 'xxl') {
		return {
			...style,
			...{
				pointHoverRadius: 0.5,
				pointRadius: 0,
				pointHitRadius: 0.5
			}
		};
	}

	return {
		...style
	};
};

const aStripChartStyle = {
	fill: false,
	lineTension: 0,
	borderColor: colours.earthiGrassGreen,
	borderWidth: 1,
	borderJoinStyle: 'round',
	pointStyle: 'circle',
	pointBackgroundColor: '#fff',
	pointRadius: 1,
	pointBorderWidth: 1,
	pointHoverRadius: 2,
	pointHoverBorderWidth: 1,
	pointHoverBorderColor: 'rgba(86,86,86,1)',
	pointHitRadius: 2
};

const rStripChartStyle = {
	...aStripChartStyle,
	borderColor: colours.earthiDeepSea
};

const aStripChartOptions = {
	responsive: true,
	maintainAspectRatio: false,
	animation: {
		duration: 0
	},
	layout: {
		padding: {
			left: 6,
			right: 7,
			top: 5,
			bottom: 5
		}
	},
	title: {
		display: false
	},
	legend: {
		display: false
	},

	tooltips: {
		mode: 'label',
		callbacks: {
			title: function (tooltipItem, data) {},

			label: function (tooltipItem, data) {
				return `${tooltipItem.yLabel}  ${tooltipItem.xLabel}`;
			}
		}
	},

	scales: {
		xAxes: [
			{
				type: 'time',
				// time: {
				// 	unit: 'month'
				// },
				ticks: {
					display: false
				},
				gridLines: {
					drawBorder: false,
					zeroLineWidth: 1,
					zeroLineColor: 'rgba(0, 0, 0, 0.1)',
					tickMarkLength: 1
				}
			}
		],
		yAxes: [
			{
				gridLines: {
					drawBorder: false,
					tickMarkLength: 0,
					zeroLineColor: 'rgba(0, 0, 0, 0.1)'
				},
				ticks: {
					display: false,
					min: 0,
					max: 100
				}
			}
		]
	},
	annotation: {
		annotations: [
			{
				type: 'line',
				mode: 'horizontal',
				scaleID: 'y-axis-0',
				value: 50,
				borderColor: colours.savantCopper,
				borderWidth: 1
			}
		]
	}
};

const rStripChartOptions = {
	...aStripChartOptions,
	annotation: {},
	scales: {
		...aStripChartOptions.scales,
		yAxes: aStripChartOptions.scales.yAxes.map((ya) => ({
			...ya,
			ticks: {
				display: false,
				min: 0
			}
		}))
	}
};

const pStripChartOptions = {
	...rStripChartOptions,
	tooltips: {
		...rStripChartOptions.tooltips,
		callbacks: {
			...rStripChartOptions.tooltips.callbacks,
			label: function (tooltipItem, data) {
				return `${tooltipItem.yLabel} t  ${tooltipItem.xLabel}`;
			}
		}
	}
};

const barMonthChartOptions = {
	responsive: true,
	hover: { mode: null },
	maintainAspectRatio: false,
	animation: {
		duration: 0
	},
	layout: {
		padding: {
			left: 6,
			right: 7,
			top: 5,
			bottom: 30
		}
	},
	title: {
		display: false
	},
	legend: {
		display: false
	},
	tooltips: {
		mode: 'label',
		callbacks: {
			title: function (tooltipItem, data) {},

			label: function (tooltipItem, data) {
				return `${tooltipItem.yLabel} t  ${tooltipItem.xLabel}`;
			},
			labelColor: (tooltipItem, data) => {
				return {
					borderColor: colours.earthiDeepSea,
					backgroundColor: '#ffff'
				};
			}
		}
	},
	scales: {
		yAxes: [
			{
				ticks: {
					beginAtZero: true,
					display: false
				}
			}
		],
		xAxes: [
			{
				ticks: {
					autoSkip: false,
					labelOffset: 10,
					display: true,
					minRotation: 300,
					maxRotation: 300,
					padding: 20,
					callback: (val) => {
						const date = moment.utc(val, 'MMM YYYY');

						return date.month() === 0
							? date.format('YYYY')
							: date.format('MMM');
					}
				}
			}
		]
	}
};

const barWeekChartOptions = {
	...barMonthChartOptions,
	tooltips: {
		...barMonthChartOptions.tooltips,
		callbacks: {
			...barMonthChartOptions.tooltips.callbacks,
			label: function (tooltipItem, data) {
				return `${tooltipItem.yLabel} t  W${moment
					.utc(tooltipItem.xLabel, 'WW GGGG')
					.format('WW')} ${moment
					.utc(tooltipItem.xLabel, 'WW GGGG')
					.startOf('isoWeek')
					.format('Do MMM')} - ${moment
					.utc(tooltipItem.xLabel, 'WW GGGG')
					.endOf('isoWeek')
					.format('Do MMM')}`;
			}
		}
	},
	scales: {
		...barMonthChartOptions.scales,
		xAxes: barMonthChartOptions.scales.xAxes.map((xa) => ({
			...xa,
			// afterBuildTicks: (axis) => {
			// 	let values = axis.ticks.map((t) => t.value);
			// 	console.log('values', axis);
			// },
			ticks: {
				...xa.ticks,
				// autoSkip: false,
				// labelOffset: 10,
				callback: (val, index) => {
					const date = moment(
						moment.utc(val, 'WW GGGG').format('YYYY-MM-DD')
					).utc();
					if (date.month() === 0 && date.week() === 1)
						return date.format('YYYY');
					else if (date.month() === 11 && date.week() === 1) {
						return date.add(1, 'year').format('YYYY');
					} else if (
						date.month() % 2 === 0 &&
						date.month() !== 0 &&
						date.date() <= 7
					) {
						return date.format('MMM');
					} else if (index === 0) {
						return date.format('YYYY');
					} else return '';
				}
				// font: (value, data) => {
				// 	console.log('value', value);
				// 	console.log('data', data);
				// }
			}
		}))
	}
};

const barYearChartOptions = {
	...barMonthChartOptions,
	tooltips: {
		...barMonthChartOptions.tooltips,
		callbacks: {
			...barMonthChartOptions.tooltips.callbacks,
			label: function (tooltipItem, data) {
				return `${tooltipItem.yLabel} t  Y${tooltipItem.xLabel}`;
			}
		}
	}
};

const plugins = [
	{
		//plugin which adds different coloured shaded area above and below 50 line
		beforeRender: function (chart) {
			const posColour = colours.earthiVividGreenAlpha;
			const negColour = colours.savantSmelterIdentifyAlpha;
			const yScale = chart.scales['activity-dispersion'];
			if (!yScale) return;

			chart.data.datasets.forEach((ds, i) => {
				//skip threshold line and do not shade multiple plots
				if (i === 0 || i > 1 || (ds.data && !ds.data.length)) return;

				const yValues = ds.data.filter((d) => d.y !== null).map((d) => d.y);
				const max = Math.max(...yValues);
				const min = Math.min(...yValues);

				const zero = yScale.getPixelForValue(50);
				const top = max > 50 ? yScale.getPixelForValue(max) : zero;
				const bottom = min < 50 ? yScale.getPixelForValue(min) : zero;
				const ratio = Math.min((zero - top) / (bottom - top), 1);
				const gradientFill = chart.ctx.createLinearGradient(0, top, 0, bottom);
				gradientFill.addColorStop(0, posColour);
				gradientFill.addColorStop(ratio, posColour);
				gradientFill.addColorStop(ratio, negColour);
				gradientFill.addColorStop(1, negColour);

				ds._meta[Object.keys(ds._meta)].dataset._model.backgroundColor =
					gradientFill;
			});
		}
	},
	{
		afterDraw: function (chart) {
			if (chart.data.datasets[0].data.length === 0) {
				var ctx = chart.chart.ctx;
				ctx.save();
				ctx.textAlign = 'center';
				ctx.textBaseline = 'middle';
				ctx.font = '22px Arial';
				ctx.fillStyle = 'gray';
				ctx.fillText(
					'No data available',
					chart.chart.width / 2,
					chart.chart.height / 2
				);
				ctx.restore();
			}
		}
	}
];

export {
	colourPalette,
	fChartStyle,
	aChartStyle,
	aThresholdStyle,
	fChartOptions,
	aChartOptions,
	cChartOptions,
	pChartOptions,
	pwChartOptions,
	pmChartOptions,
	pyChartOptions,
	eChartOptions,
	ewChartOptions,
	emChartOptions,
	eyChartOptions,
	genChartStyle,
	annotationsStem,
	annotationStem,
	aStripChartStyle,
	rStripChartStyle,
	aStripChartOptions,
	rStripChartOptions,
	pStripChartOptions,
	barMonthChartOptions,
	barWeekChartOptions,
	barYearChartOptions,
	plugins
};
