import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { Card, CardBody, Col, Container, Progress, Row } from 'reactstrap';
import { Chart, Line } from 'react-chartjs-2';
import { Auth } from 'aws-amplify';

import moment from 'moment';

import { Crumbs } from 'containers';
import {
	fetchLocations,
	resetLocations,
	fetchSteelSmelterData,
	resetSteelSmelterData,
	fetchSteelSmelterRecent
} from 'actions';
import {
	chartStyle,
	snapshotsChartStyle,
	chartOptions,
	plugins
} from './smelterDashletChartConfig';

import './Home.scss';

// import dashboardMap from 'images/dashboard_map.png';

const store = require('store');

class SteelHome extends Component {
	state = {};

	async componentDidMount() {
		plugins.forEach((plugin) => {
			//register plugin if it is not already registered
			if (!Chart.pluginService._plugins.includes(plugin)) {
				Chart.pluginService.register(plugin);
			}
		});
		const authUser = await Auth.currentAuthenticatedUser();
		const userRole = authUser.attributes['custom:userRole'] || '';
		this.isSnapshots =
			userRole === 'user' &&
			!authUser.signInUserSession.idToken.payload['cognito:groups']?.length;
		this.props.fetchLocations({ commodity: 'steel' });
		this.props.fetchSteelSmelterRecent();
	}

	componentWillUnmount() {
		// Set the visual for the home screen copy
		store.set('home-copy-show', true);
		this.props.resetSteelSmelterData();
		this.props.resetLocations();
	}

	componentDidUpdate({ locations: { regions: prevRegions } }) {
		const {
			locations: { regions, countries }
		} = this.props;
		if (regions.length === 1 && regions.length > prevRegions.length) {
			this.props.fetchSteelSmelterData({
				grouping: 'countries',
				ids: countries?.map(({ id }) => id).join(',') || '',
				plant: ''
			});
		} else if (regions.length > prevRegions.length) {
			this.props.fetchSteelSmelterData({
				grouping: '',
				ids: '',
				plant: ''
			});
		}
	}

	onInputSelect = ({ target: { id, value } }) => {
		this.setState({
			[id]: value
		});
	};

	buildLoadingMessage() {
		return (
			<Card className="mb-3">
				<CardBody>
					<p className="h6 dash-header">Chart data loading...</p>
					<Progress animated value="100" />
				</CardBody>
			</Card>
		);
	}

	renderSmelterData() {
		const {
			steelSmelterData = {},
			steelSmelterDataError = '',
			locations: { regions, countries }
		} = this.props;
		if (steelSmelterDataError) {
		} else if (!steelSmelterData.combinedIdx && !steelSmelterData.singleIdx) {
			return this.buildLoadingMessage();
		}
		const style = !this.isSnapshots ? chartStyle : snapshotsChartStyle;
		let datasets = [];
		if (regions?.length > 1) {
			datasets = [
				{
					label: 'Global Inactive Capacity Index',
					data: [],
					...style,
					borderColor: '#FFA500',
					backgroundColor: '#FFA500',
					pointBackgroundColor: '#FFA500'
				}
			];
		} else {
			datasets = [
				{
					label: `${countries
						?.map(({ name }) => name)
						.join(',')} Inactive Capacity Index`,
					data: [],
					...style,
					borderColor: '#FFA500',
					backgroundColor: '#FFA500',
					pointBackgroundColor: '#FFA500'
				}
			];
		}
		if (steelSmelterData.combinedIdx) {
			steelSmelterData.combinedIdx.forEach(({ t, f }) => {
				datasets[0].data.push({
					t: moment.utc(t).format('YYYY-MM-DD'),
					y: f
				});
			});
		} else if (steelSmelterData.singleIdx) {
			steelSmelterData.singleIdx[0].data.forEach(({ t, f }) => {
				datasets[0].data.push({
					t: moment.utc(t).format('YYYY-MM-DD'),
					y: f
				});
			});
		}

		return (
			<Card className="mb-3">
				<CardBody className="p-2">
					<p className="h6 dash-header">Recent smelter activity</p>
					<NavLink
						to="/steel/smelters"
						className="btn btn-light smaller pull-right"
					>
						Show more
						<i className="fa fa-external-link pl-2" />
					</NavLink>
					<Line data={{ datasets }} options={chartOptions} height={200} />
				</CardBody>
			</Card>
		);
	}

	renderSmelterMapPicture() {
		return (
			<Card className="mb-3 smelter-map-dashlet">
				<CardBody className="p-2">
					<Fragment>
						<p className="h6 mb-3 dash-header">World smelters</p>
						<p className="mb-0">
							<img
								src={`${process.env.PUBLIC_URL}/images/steel-map-all.png`}
								className="map-viewer"
								alt="Smelter locations"
							/>
						</p>
					</Fragment>
				</CardBody>
			</Card>
		);
	}

	renderRecentData() {
		const { steelSmelterRecent = [] } = this.props;

		const recents = steelSmelterRecent.map(
			({ name, captured, country }, idx) => (
				<tr key={`capture${idx}`}>
					<td>
						<i className="fa fa-calendar mr-2" />
						{moment(captured).format('MMM Do')}
						{/* <span className="text-muted"> {moment(capture_date).format('h:mm:ss A')}</span> */}
					</td>
					<td className="text-right">{name}</td>
					{/* <td className="text-right">{country}</td> */}
				</tr>
			)
		);

		return (
			steelSmelterRecent.length > 0 && (
				<Card className="mb-3">
					<CardBody className="p-2">
						<p className="h6 dash-header">Recent smelter observations</p>

						<NavLink
							to="/steel/smeltersMap/all"
							className="btn btn-light smaller pull-right"
						>
							Show smelters
							<i className="fa fa-external-link pl-2" />
						</NavLink>
						<table className="table small mb-0 recentCaptures">
							<tbody>{recents}</tbody>
						</table>
					</CardBody>
				</Card>
			)
		);
	}

	renderRecentObservations() {
		const { steelSmelterRecent = [] } = this.props;
		const recents = steelSmelterRecent.map(
			({ name, captured, end_date, state, country }, idx) => (
				<div className="flex-row" role="rowgroup" key={`capture${idx}`}>
					<div className="flex-cell" role="cell">
						<div className="date-wrapper">
							<span>
								<i className="fa fa-calendar mr-2" />
							</span>
							<span>{moment.utc(captured).format('D MMM')}</span>
						</div>
					</div>
					<div className="flex-cell pl-3 pr-2" role="cell">
						{/* <NavLink to={`/steel/smeltersMap/${region}`}>{name}</NavLink> */}
						<span>{name}</span>
					</div>
					<div className="flex-cell" role="cell">
						<div className="status-wrapper">
							{/* <span className="text-muted">{country}</span> */}
							<span
								className={`status ${
									end_date
										? 'text-light status-shutdown'
										: state === 1
										? 'text-dark status-active'
										: 'text-light status-inactive'
								}`}
							>
								{`${
									end_date ? 'shutdown' : state === 1 ? 'active' : 'inactive'
								}`}
							</span>
						</div>
					</div>
				</div>
			)
		);

		return (
			steelSmelterRecent.length > 0 && (
				<Card className="mb-3">
					<CardBody className="p-2">
						<div className="d-flex justify-content-between align-items-center mb-2">
							<p className="h6 dash-header">Recent smelter observations</p>

							<NavLink
								to="/steel/smeltersMap/all"
								className="btn btn-light smaller"
							>
								Show smelters
								<i className="fa fa-external-link pl-2" />
							</NavLink>
						</div>

						<div className="recentCaptures mb-0 mr-n2" role="table">
							<div className="table-body" role="list">
								{recents}
							</div>
						</div>
					</CardBody>
				</Card>
			)
		);
	}

	render() {
		return (
			<DocumentTitle title="GAINS | Steel Dashboard">
				<div className="content-wrapper dashboard">
					<Container fluid>
						<Crumbs type="steel" />
						<Row noGutters>
							<Col xs="5" sm="5" md="5" lg="6" xl="8" className="pr-3">
								{/* {isEnterpriseOrSales && this.renderSmelterMap()} */}
								{this.renderSmelterMapPicture()}
							</Col>
							<Col xs="7" sm="7" md="7" lg="6" xl="4">
								{this.renderSmelterData()}
								{this.renderRecentObservations()}
							</Col>
						</Row>
					</Container>
				</div>
			</DocumentTitle>
		);
	}
}

const mapStateToProps = ({
	locations,
	steelSmelterAllMapData,
	steelSmelterData,
	steelSmelterRecent
}) => {
	return {
		locations,
		steelSmelterAllMapData,
		steelSmelterData: steelSmelterData.data,
		steelSmelterDataError: steelSmelterData.error,
		steelSmelterRecent
	};
};

const mapDispatchToProps = (dispatch) => ({
	// fetchSteelSmelterAllMapData: () => dispatch(fetchSteelSmelterAllMapData()),
	fetchLocations: (filterData) => dispatch(fetchLocations(filterData)),
	resetLocations: () => dispatch(resetLocations()),
	fetchSteelSmelterData: (filterData) =>
		dispatch(fetchSteelSmelterData(filterData)),
	resetSteelSmelterData: () => dispatch(resetSteelSmelterData()),
	fetchSteelSmelterRecent: () => dispatch(fetchSteelSmelterRecent())
});

export default connect(mapStateToProps, mapDispatchToProps)(SteelHome);
