import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { Auth } from 'aws-amplify';

import DocumentTitle from 'react-document-title';
import {
	Alert,
	Button,
	ButtonGroup,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Form,
	Input,
	Label,
	Row,
	Progress
	// UncontrolledTooltip
} from 'reactstrap';
import { Line, Chart } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
import moment from 'moment';
import { CSVLink } from 'react-csv';

import {
	fetchLastUploadDate,
	resetLastUploadDate,
	fetchSteelSmelterCapacity,
	resetSteelSmelterCapacity,
	fetchLocations,
	resetLocations,
	fetchGroups,
	resetGroups
} from 'actions';
import {
	colourPalette,
	cChartOptions,
	genChartStyle,
	plugins
} from './chartConfig';
import { Crumbs } from 'containers';
import { DateRangePopover } from 'components';
import MultiSelect from 'components/multiselect/MultiSelect';

import './Smelters.scss';

const config = {
	dateRangeDayLimit: 10,
	apiDateFormat: 'YYYYMMDD',
	dspDateFormat: 'YYYY-MM-DD',
	globalRegion: {
		name: 'Capacity',
		colour: '#FFA500',
		title: 'Global'
	},
	combinedRegion: {
		name: 'Combined capacity',
		colour: '#FFA500'
	},
	multipleSelectionLimit: 5
};

class SteelSmeltersCapacity extends Component {
	state = {
		groupType: 'region',
		global: true,
		selectedGrouping: {},
		selected: 'regions',
		compareCombine: '',
		dateMin: moment.utc('2016-03-01'),
		dateMax: moment.utc(),
		dateFrom: moment.utc(),
		dateTo: moment.utc(),
		smoothingDays: 7,
		isInitDateRange: false,
		shortDateRange: false,
		showDataWarning: false,
		canDownload: false,
		plantTypes: [],
		selectedPlantType: '',
		dataByPlant: { combined: '', bfbof: '', eaf: '' }
	};

	async componentDidMount() {
		plugins.forEach((plugin) => {
			//register plugin if it is not already registered
			if (!Chart.pluginService._plugins.includes(plugin)) {
				Chart.pluginService.register(plugin);
			}
		});

		const authUser = await Auth.currentAuthenticatedUser();
		const userRole = authUser.attributes['custom:userRole'] || '';
		this.isSnapshots =
			userRole === 'user' &&
			!authUser.signInUserSession.idToken.payload['cognito:groups']?.length;

		this.props.fetchLocations({ commodity: 'steel' });
		this.props.fetchGroups({ commodity: 'steel' });
		this.props.fetchLastUploadDate({ commodity: 'steel' });
	}

	handleCountryRestrictedCondition = () => {
		const {
			locations: { countries }
		} = this.props;

		this.setState(
			{
				groupType: 'country',
				selected: 'countries',
				global: false
			},
			() => this.handleMultipleSelections(countries)
		);
	};

	componentDidUpdate(
		{
			lastUploadDate: prevLastUploadDate,
			steelSmelterCapacity: prevSteelSmelterData,
			locations: { regions: prevRegions }
		},
		{
			isInitDateRange: prevIsInitDateRange,
			dateFrom: prevDateFrom,
			dateTo: prevDateTo,
			selected: prevSelected,
			selectedGrouping: prevSelectedGrouping,
			global: prevGlobal,
			compareCombine: prevCompareCombine,
			selectedPlantType: prevSelectedPlantType
		}
	) {
		const {
			lastUploadDate,
			steelSmelterCapacity,
			locations: { regions }
		} = this.props;
		const {
			isInitDateRange,
			dateFrom,
			dateTo,
			selected,
			selectedGrouping,
			global,
			selectedPlantType,
			dataByPlant,
			plantTypes
		} = this.state;

		//fetchSteelNewestDate requested in ComponentDidMount(), ensure newestDate is received
		if (lastUploadDate === '') {
			if (regions.length === 1 && regions.length !== prevRegions.length) {
				this.handleCountryRestrictedCondition();
			}
			return;
		}

		//Date setting
		if (lastUploadDate !== prevLastUploadDate) {
			this.updateDateHandling({ lastUploadDate });
		}

		if (regions.length === 1 && regions.length !== prevRegions.length) {
			this.handleCountryRestrictedCondition();
			return;
		}

		//once date range is set, fetch data
		if (
			(isInitDateRange !== prevIsInitDateRange && regions.length) ||
			(isInitDateRange && regions.length !== prevRegions.length)
		) {
			this.filterData();
		}

		if (
			(isInitDateRange === prevIsInitDateRange &&
				(prevDateFrom !== dateFrom || prevDateTo !== dateTo)) ||
			selected !== prevSelected ||
			selectedGrouping[selected]?.length !==
				prevSelectedGrouping[selected]?.length ||
			((global || selectedGrouping[selected]?.length === 1) &&
				selectedPlantType !== prevSelectedPlantType &&
				selectedPlantType &&
				!dataByPlant[selectedPlantType]) ||
			(selectedGrouping[selected]?.length >= 1 &&
				selectedPlantType !== prevSelectedPlantType) ||
			global !== prevGlobal
		) {
			this.filterData();
		}
		if (
			Object.keys(steelSmelterCapacity).length &&
			!Object.keys(prevSteelSmelterData).length &&
			(global || selectedGrouping[selected]?.length)
		) {
			if (
				selectedGrouping[selected]?.length >= 1 &&
				plantTypes.length &&
				selectedGrouping[selected].map(({ name }) => name).length >
					steelSmelterCapacity?.singleIdx.map(({ name }) => name).length
			) {
				toastr.warning(
					'Capacity',
					`No data for ${
						selectedPlantType === 'eaf' ? 'EAF' : 'BF/BOF'
					} in ${selectedGrouping[selected]
						.map(({ name }) => name)
						.filter(
							(n) =>
								!steelSmelterCapacity?.singleIdx
									.map(({ name }) => name)
									.includes(n)
						)
						.join(', ')}
				`
				);
			}
			if (selectedGrouping[selected]?.length > 1) {
				return;
			}
			selectedPlantType
				? global
					? this.setState({
							dataByPlant: {
								...dataByPlant,
								[selectedPlantType]: steelSmelterCapacity.combinedIdx
							}
					  })
					: selectedGrouping[selected]?.length === 1 &&
					  this.setState({
							dataByPlant: {
								...dataByPlant,
								[selectedPlantType]:
									steelSmelterCapacity.singleIdx[0]?.data || []
							}
					  })
				: global
				? this.setState({
						dataByPlant: {
							...dataByPlant,
							combined: steelSmelterCapacity.combinedIdx
						}
				  })
				: selectedGrouping[selected]?.length === 1 &&
				  this.setState({
						dataByPlant: {
							...dataByPlant,
							combined: steelSmelterCapacity.singleIdx[0]?.data || []
						},
						selectedPlantType:
							!plantTypes.length ||
							plantTypes.every((pt) => dataByPlant[pt]) === true
								? selectedPlantType
								: plantTypes.find((pt) => dataByPlant[pt] === '')
				  });
		}
	}

	componentWillUnmount() {
		this.props.resetSteelSmelterCapacity();
		this.props.resetLocations();
		this.props.resetGroups();
		this.props.resetLastUploadDate();
	}

	async updateDateHandling({ lastUploadDate }) {
		const maxDate = moment.utc(lastUploadDate);

		let downloadState = {};

		let dateState = {
			dateFrom: !this.isSnapshots
				? maxDate.clone().subtract(1, 'y').subtract(1, 'd')
				: maxDate.clone().subtract(1, 'y'),
			dateTo: !this.isSnapshots
				? maxDate.clone().subtract(1, 'd')
				: maxDate.clone(),
			dateMax: !this.isSnapshots
				? maxDate.clone().subtract(1, 'd')
				: maxDate.clone()
		};

		this.setState({
			...dateState,
			...downloadState,
			isInitDateRange: true
		});
	}

	// Radio button click for combine/compare
	async onRadioBtnClick(rSelected) {
		this.setState({ ...rSelected });
	}

	// Global button click
	async onGlobalBtnClick() {
		const { global, selected, selectedGrouping } = this.state;
		let newState = {};
		//reset regions when switching to global, but only for current view
		if (!global) {
			const newSelectedGrouping = { ...selectedGrouping };
			newSelectedGrouping[selected] = [];
			newState = { ...newState, selectedGrouping: newSelectedGrouping };
		}

		//toggle global
		newState = {
			...newState,
			global: !global,
			selectedPlantType: '',
			plantTypes: [],
			dataByPlant: { combined: '', bfbof: '', eaf: '' },
			compareCombine: ''
		};

		this.setState(newState);
	}

	// region buttons
	async onRegionBtnClick({ name, val }) {
		const { selected, selectedGrouping, selectedPlantType, plantTypes } =
			this.state;
		//existing regions
		const selectedIds = selectedGrouping[selected]?.map(({ id }) => id) || [];
		const oldSelectedCount = selectedIds.length;

		let selectedRegions = [];

		if (selectedIds.includes(val)) {
			selectedRegions = selectedGrouping[selected].filter(
				({ id }) => id !== val
			);
		} else {
			const selectedColours =
				selectedGrouping[selected]?.map(({ colour }) => colour) || [];
			const colour =
				selectedColours.length === 0
					? colourPalette[0]
					: colourPalette.filter((c) => !selectedColours.includes(c))[0];
			selectedRegions =
				selectedIds.length === 0
					? [{ id: val, name, colour }]
					: [...selectedGrouping[selected], { id: val, name, colour }];
		}
		const newSelectedGrouping = {
			...selectedGrouping,
			[selected]: selectedRegions
		};
		const newSelectedCount = newSelectedGrouping[selected].length;

		const global = newSelectedCount ? false : true;

		let newState = {
			selectedGrouping: newSelectedGrouping,
			global: global,
			dataByPlant: { combined: '', bfbof: '', eaf: '' },
			plantTypes: newSelectedCount && plantTypes.length <= 1 ? plantTypes : [],
			selectedPlantType:
				newSelectedCount && plantTypes.length <= 1 ? selectedPlantType : ''
		};

		if (newSelectedCount <= 1) {
			newState = {
				...newState,
				compareCombine: ''
			};
		} else if (oldSelectedCount <= 1) {
			//regions count increased
			newState = { ...newState, compareCombine: 'combine' };
		}
		this.setState(newState);
	}

	async onPlantBtnClick({ key }) {
		const {
			plantTypes,
			dataByPlant,
			selectedGrouping,
			selected,
			compareCombine
		} = this.state;

		if (plantTypes.includes(key)) {
			const newPlantTypes = plantTypes.filter((pt) => pt !== key);
			this.setState({
				plantTypes: newPlantTypes,
				selectedPlantType: newPlantTypes.length ? newPlantTypes[0] : '',
				compareCombine:
					selectedGrouping[selected]?.length > 1 ? compareCombine : ''
			});
		} else if (selectedGrouping[selected]?.map(({ id }) => id).length > 1) {
			this.setState({
				plantTypes: [key],
				selectedPlantType: key
			});
		} else {
			const newPlantTypes = [...plantTypes, key];

			this.setState({
				compareCombine: newPlantTypes.length <= 1 ? '' : 'combine',
				selectedPlantType:
					newPlantTypes.length > 1 && dataByPlant.combined?.length === 0
						? ''
						: key,
				plantTypes: newPlantTypes
			});
		}
	}

	handleMultipleSelections = async (vals) => {
		const { selected, selectedGrouping, selectedPlantType, plantTypes } =
			this.state;

		const oldSelectedIds =
			selectedGrouping[selected]?.map(({ id }) => id) || [];
		const newSelectedIds = vals.map(({ id }) => id);

		let newSelectedOptions = [];
		//removed
		if (oldSelectedIds.length > newSelectedIds.length) {
			newSelectedOptions = selectedGrouping[selected].filter(({ id }) =>
				newSelectedIds.includes(id)
			);
		} else {
			//one option added
			const newAdded = vals.filter(({ id }) => !oldSelectedIds.includes(id));
			const { id, name } = [...newAdded][0];
			const selectedColours =
				selectedGrouping[selected]?.map(({ colour }) => colour) || [];
			const colour =
				selectedColours.length === 0
					? colourPalette[0]
					: colourPalette.filter((c) => !selectedColours.includes(c))[0];
			newSelectedOptions =
				oldSelectedIds.length === 0
					? [{ id, name, colour }]
					: [...selectedGrouping[selected], { id, name, colour }];
		}

		const newSelectedGrouping = {
			...selectedGrouping,
			[selected]: newSelectedOptions
		};
		const newSelectedCount = newSelectedGrouping[selected].length;
		const global = newSelectedCount ? false : true;

		let newState = {
			selectedGrouping: newSelectedGrouping,
			global: global,
			dataByPlant: { combined: '', bfbof: '', eaf: '' },
			plantTypes: newSelectedCount && plantTypes.length <= 1 ? plantTypes : [],
			selectedPlantType:
				newSelectedCount && plantTypes.length <= 1 ? selectedPlantType : ''
			// compareCombine: selectedIds.length <= 1 ? '' : compareCombine
		};
		if (newSelectedCount <= 1) {
			newState = { ...newState, compareCombine: '' };
		} else if (oldSelectedIds.length <= 1) {
			//options count increased
			newState = { ...newState, compareCombine: 'combine' };
		}
		this.setState(newState);
	};

	// Region toggling for the select all / none button
	async onToggleAllRegions() {
		const { selectedGrouping, compareCombine, plantTypes, selectedPlantType } =
			this.state;
		const { locations } = this.props;
		const { regions } = locations;

		if (!selectedGrouping.hasOwnProperty(['regions'])) {
			selectedGrouping['regions'] = [];
		}

		let selectedRegions = [];
		if (selectedGrouping['regions'].length < regions.length) {
			const newRegions = regions.filter(
				({ id }) =>
					!selectedGrouping['regions'].map(({ id }) => id).includes(id)
			);
			const selectedColours =
				selectedGrouping['regions']?.map(({ colour }) => colour) || [];
			const newColours = colourPalette.filter(
				(c) => !selectedColours.includes(c)
			);
			newRegions.forEach((r, i) => (r.colour = newColours[i]));
			selectedRegions = [...selectedGrouping['regions'], ...newRegions];
		}
		const global = selectedRegions.length === 0;
		const newSelectedGrouping = {
			...selectedGrouping,
			regions: selectedRegions
		};
		const newCompareCombine =
			selectedGrouping['regions'].length > 1 ? compareCombine : 'combine';

		await this.setState({
			selectedGrouping: newSelectedGrouping,
			compareCombine: newSelectedGrouping['regions'].length
				? newCompareCombine
				: '',
			dataByPlant: { combined: '', bfbof: '', eaf: '' },
			plantTypes:
				selectedRegions.length && plantTypes.length <= 1 ? plantTypes : [],
			selectedPlantType:
				selectedRegions.length && plantTypes.length <= 1
					? selectedPlantType
					: '',
			global
		});
		// this.filterData();
	}

	// Handle date range selection event
	async onDateRangeSelect({ startDate, endDate, selectedGrouping, selected }) {
		//startDate, endDate - moment objects
		const newPlantState =
			global || selectedGrouping[selected]?.length === 1
				? {
						plantTypes: [],
						selectedPlantType: '',
						dataByPlant: { combined: '', bfbof: '', eaf: '' },
						compareCombine: ''
				  }
				: {};

		this.setState({
			dateFrom: startDate,
			dateTo: endDate,
			shortDateRange:
				endDate.diff(startDate, 'days') < config.dateRangeDayLimit,
			...newPlantState
		});

		// !this.state.shortDateRange && this.filterData();
	}

	//sort plain array of regions' names or array of region objects
	sortRegions = (regions) => {
		// const { steelPseudoGroups } = this.props;
		// const { groupType } = this.state;
		// if (!regions.length) {
		// 	return regions;
		// }
		// const regionOrder = steelPseudoGroups
		// 	.filter((g) => g.type === groupType)
		// 	.map((g) => g.key);
		// return regionOrder.reduce((acc, r) => {
		// 	let filtered = [];
		// 	filtered = regions.filter((elem) => {
		// 		return Object.keys(elem).length && Object.keys(elem).includes('value')
		// 			? elem['value'] === r
		// 			: elem === r;
		// 	});
		// 	if (filtered.length) {
		// 		acc.push(filtered[0]);
		// 	}
		// 	return acc;
		// }, []);
	};

	// InputSelect for the smoothing selection or group type
	onInputSelect = async ({ target: { value, name } }) => {
		const { selectedGrouping } = this.state;

		if (name === 'smoothingDays') {
			//values inside drop down list are strings
			this.setState({
				[name]: parseInt(value)
			});
			return;
		}
		if (name === 'groupType') {
			let filtering = '';

			if (value === 'region') {
				filtering = 'regions';
			} else if (value === 'country') {
				filtering = 'countries';
			} else {
				filtering = value;
			}

			const global = selectedGrouping[filtering]?.length ? false : true;
			this.setState({
				plantTypes: [],
				selectedPlantType: '',
				dataByPlant: { combined: '', bfbof: '', eaf: '' },
				selected: filtering,
				groupType: value,
				compareCombine: '',
				global: global
			});
		}
	};

	// Run action creator to fetch smelter data based on filter form state
	filterData() {
		const {
			global,
			dateFrom,
			dateTo,
			selected,
			selectedGrouping,
			selectedPlantType
		} = this.state;
		this.props.resetSteelSmelterCapacity();

		if (global) {
			this.props.fetchSteelSmelterCapacity({
				grouping: '',
				ids: '',
				plant: selectedPlantType,
				dateFrom: dateFrom.format(config.apiDateFormat),
				dateTo: dateTo.format(config.apiDateFormat)
			});
		} else if (selectedGrouping[selected]?.length > 0) {
			this.props.fetchSteelSmelterCapacity({
				grouping:
					selected === 'regions' || selected === 'countries'
						? selected
						: 'groups',
				ids: selectedGrouping[selected]?.map(({ id }) => id).join(',') || '',
				plant: selectedPlantType,
				dateFrom: dateFrom.format(config.apiDateFormat),
				dateTo: dateTo.format(config.apiDateFormat)
			});
		}
	}

	getChartType() {
		const { dateFrom, dateTo, showDispersion } = this.state;
		const days = dateTo.diff(dateFrom, 'd');
		const type = showDispersion ? 'a' : 'f';
		let size = '';

		if (days <= 50) {
			size = 'sm';
		} else if (days > 50 && days <= 100) {
			size = 'md';
		} else if (days > 100 && days <= 200) {
			size = 'lg';
		} else if (days > 200) {
			size = 'xl';
		} else if (days > 300) {
			size = 'xxl';
		}
		return { size, type, isSnapshots: this.isSnapshots };
	}

	// Build the card header element for display
	buildCardHeader() {
		const {
			compareCombine,
			global,
			groupType,
			plantTypes,
			selectedGrouping,
			selected
		} = this.state;

		const groupName = groupType.substring(6);
		let selection = groupType;
		if (groupType.startsWith('group_')) {
			selection = groupName;
		}

		let selections = 'groups';
		if (groupType === 'region') {
			selections = 'regions';
		} else if (groupType === 'country') {
			selections = 'countries';
		} else if (groupType.startsWith('group_')) {
			selections = `${groupName} ${selections}`;
		}

		let title = '';
		if (global) {
			title = `Capacity: ${config.globalRegion.title}`;
		} else if (
			compareCombine === 'combine' &&
			selectedGrouping[selected]?.length > 1
		) {
			title = `Capacity: Combine multiple ${selections}`;
		} else if (
			compareCombine === 'compare' &&
			selectedGrouping[selected]?.length > 1
		) {
			title = `Capacity: Compare multiple ${selections}`;
		} else {
			title = `Capacity: Single ${selection}`;
		}
		title = `${title} ${plantTypes.length ? '-' : ''} ${plantTypes
			.map((pt) => (pt === 'eaf' ? 'EAF' : 'BF/BOF'))
			.join(', ')}`;
		return (
			<CardHeader>
				<i className="fa fa-line-chart" aria-hidden="true" /> {title}
			</CardHeader>
		);
	}

	buildRegionButton({ id, name, title }) {
		const { selectedGrouping, selected } = this.state;

		return (
			<Fragment key={`region${name}`}>
				<Button
					id={`region_${id}`}
					outline
					size="sm"
					color="secondary"
					onClick={() => this.onRegionBtnClick({ name, val: id })}
					active={selectedGrouping[selected]?.map(({ id }) => id).includes(id)}
					aria-label={title}
					className="light-active-border text-nowrap"
				>
					{title}
				</Button>
			</Fragment>
		);
	}

	buildPlantButton({ id, name, title }) {
		const {
			plantTypes,
			dataByPlant,
			selectedPlantType,
			selectedGrouping,
			selected,
			global
		} = this.state;
		const key = name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
		return (
			<Fragment key={`plant${name}`}>
				<Button
					id={`plant_${id}`}
					outline
					size="sm"
					color="secondary"
					onClick={() => this.onPlantBtnClick({ key, id })}
					active={plantTypes.includes(key)}
					disabled={
						((dataByPlant.combined === '' && plantTypes.length !== 1) ||
							(plantTypes.length === 1 &&
								dataByPlant[selectedPlantType] === '' &&
								key !== selectedPlantType)) &&
						(global || selectedGrouping[selected]?.length === 1)
					}
					aria-label={title}
					className="light-active-border"
				>
					{title}
				</Button>
			</Fragment>
		);
	}

	buildCsvDownloadButton() {
		const { global, shortDateRange, selected, selectedGrouping, canDownload } =
			this.state;
		const { steelSmelterCapacity } = this.props;

		if (
			(global || selectedGrouping[selected]?.length) &&
			!shortDateRange &&
			steelSmelterCapacity.length !== 0 &&
			canDownload
		) {
			let { csvHeaders, csvData, csvFileName } = this.getCsvDataFromProps();

			return (
				<CSVLink
					id="buttonDownload"
					filename={csvFileName}
					headers={csvHeaders}
					data={csvData}
					className="btn btn-success mb-3 pull-right btn-sm"
					onClick={() => true}
				>
					<i className="fa fa-download mr-2" aria-hidden="true" /> Download this
					data
				</CSVLink>
			);
		}

		return (
			<Button
				id="buttonDownload"
				color="success"
				disabled
				className="mb-3 pull-right"
				size="sm"
			>
				<i className="fa fa-download mr-2" aria-hidden="true" /> Download this
				data
			</Button>
		);
	}

	// Build the filter form
	buildFilterForm() {
		const { locations, groups, lastUploadDate } = this.props;
		const {
			dateMin,
			dateMax,
			dateFrom,
			dateTo,
			compareCombine,
			global,
			groupType,
			// regions,
			selectedGrouping,
			selected,
			plantTypes,
			dataByPlant
		} = this.state;

		const groupName = groupType.substring(6);

		const { regions, countries } = locations;
		const sortedRegions = regions.sort((r1, r2) =>
			r1.name > r2.name ? 1 : -1
		);
		const regionToggleHidden = regions.length < 2;
		const regionAllCopy =
			selectedGrouping['regions']?.length === regions.length ? 'None' : 'All';

		return (
			<Form>
				<Container fluid className="px-0 container-width">
					<Row noGutters>
						<Col xs="12" sm="12">
							<Label for="groupType" className="mb-2 mr-3 align-top">
								<span className="sr-only">Geographical filtering type</span>
								<Input
									id="groupType"
									name="groupType"
									type="select"
									bsSize="sm"
									className="pointered"
									defaultValue={groupType}
									onChange={this.onInputSelect}
								>
									{regions.length !== 1 && (
										<option value="region">Region filtering</option>
									)}
									<option value="country">Country filtering</option>
									{Object.keys(groups)
										.filter((g) => g !== 'plant')
										.map((g) => (
											<option key={`group_${g}`} value={`group_${g}`}>
												{g.charAt(0).toUpperCase() + g.slice(1)} filtering
											</option>
										))}
								</Input>
							</Label>
							{regions.length > 1 && (
								<Button
									id="regionGlobal"
									outline
									size="sm"
									color="secondary"
									active={global}
									aria-label="global"
									className="mb-2 light-active-border"
									onClick={() => this.onGlobalBtnClick()}
								>
									Global
								</Button>
							)}
							{groupType === 'region' && regions.length > 0 && (
								<ButtonGroup className="mb-2 ml-3">
									{sortedRegions.map(({ name, id }) => {
										return this.buildRegionButton({
											id,
											name,
											title: name
										});
									})}
								</ButtonGroup>
							)}
							{groupType === 'region' && (
								<Button
									id="regionAll"
									color="primary"
									size="sm"
									onClick={() => this.onToggleAllRegions()}
									className="mb-2 ml-1"
									style={{ width: '54px' }}
									disabled={global}
									hidden={regionToggleHidden}
								>
									{regionAllCopy}
								</Button>
							)}
							{groupType.startsWith('group_') &&
								groups[groupName].length > 0 &&
								groups[groupName].length <= 12 && (
									<ButtonGroup className="mb-2 ml-3">
										{groups[groupName].map(({ name, id }) => {
											return this.buildRegionButton({
												id,
												name,
												title: name
											});
										})}
									</ButtonGroup>
								)}
							{groupType.startsWith('group_') &&
								groups[groupName].length > 12 && (
									<MultiSelect
										options={groups[groupName]}
										values={selectedGrouping[groupType]}
										limit={config.multipleSelectionLimit}
										onMultipleSelection={this.handleMultipleSelections}
									/>
								)}
							{groupType === 'country' && (
								<MultiSelect
									options={countries}
									values={selectedGrouping.countries}
									limit={config.multipleSelectionLimit}
									onMultipleSelection={this.handleMultipleSelections}
								/>
							)}
							{(groupType === 'region' ||
								groupType === 'country' ||
								groupType.startsWith('group_')) && (
								<ButtonGroup className="mb-2 ml-5">
									{['BF/BOF', 'EAF'].map((name, i) => {
										return this.buildPlantButton({
											id: i,
											name,
											title: name
										});
									})}
								</ButtonGroup>
							)}
						</Col>
					</Row>
					<Row noGutters>
						<Col xs="12" sm="12">
							<DateRangePopover
								id="dateSelector"
								startDate={dateFrom}
								endDate={dateTo}
								minDate={dateMin}
								maxDate={dateMax}
								newestDate={lastUploadDate}
								isSnapshots={this.isSnapshots}
								onDateRangeSelected={this.onDateRangeSelect.bind(this)}
							/>
							<ButtonGroup className="mb-3 mr-3 align-bottom">
								<Button
									id="buttonCombine"
									outline
									size="sm"
									color="secondary"
									onClick={() =>
										this.onRadioBtnClick({ compareCombine: 'combine' })
									}
									active={compareCombine === 'combine'}
									disabled={
										(plantTypes.length <= 1 &&
											(global || selectedGrouping[selected]?.length === 1)) ||
										(!global && selectedGrouping[selected]?.length < 1)
									}
									aria-label="Click to combine smelter data"
								>
									Combine
								</Button>
								<Button
									id="buttonCompare"
									outline
									size="sm"
									color="secondary"
									onClick={() =>
										this.onRadioBtnClick({ compareCombine: 'compare' })
									}
									active={compareCombine === 'compare'}
									disabled={
										// plantTypes.length <= 1 &&
										// (global || selectedGrouping[selected]?.length <= 1)
										(global || selectedGrouping[selected]?.length <= 1) &&
										(plantTypes.length <= 1 ||
											(plantTypes.length >= 1 &&
												plantTypes.some((pt) => !dataByPlant[pt]?.length)))
									}
									aria-label="Click to compare smelter data"
								>
									Compare
								</Button>
							</ButtonGroup>
							{/* {this.buildCsvDownloadButton()} */}
						</Col>
					</Row>
				</Container>
			</Form>
		);
	}

	// Build csv data from the props for the download feature
	getCsvDataFromProps() {
		const {
			steelSmelterCapacity: { singleIdx = [], combinedIdx = [] }
		} = this.props;
		let { dateFrom, dateTo } = this.state;

		// Initialise our csv data
		let csvFileName = `smelter-data-${dateFrom.format(
			config.apiDateFormat
		)}-${dateTo.format(config.apiDateFormat)}.csv`;
		let csvHeaders = [{ label: 'Date', key: 't' }];
		let csvData = [];

		singleIdx.forEach(({ name, data }) => {
			data.forEach(({ t, f, a }, j) => {
				// Add the time column value to the csv data if this is the first region iteration
				// or if we've somehow got a frame error from the api data and the row is missing
				if (!csvData[j]) {
					csvData[j] = {
						t: moment.utc(t).format(config.dspDateFormat)
					};
				}
				// Generate the region-based f and σ column names
				let namefkey = `${name} Inactive Capacity`;
				// let nameakey = `${name} Activity Dispersion`;
				let nameakey = `${name} Activity`;

				// Add the column names to the csv headers if this is the first data iteration
				if (j === 0) {
					csvHeaders = [
						...csvHeaders,
						...[
							{
								label: namefkey,
								key: namefkey
							},
							{
								label: nameakey,
								key: nameakey
							}
						]
					];
				}

				// Set the f column on the csv data
				csvData[j][namefkey] = f;
				csvData[j][nameakey] = a;
			});
		});

		combinedIdx.forEach(({ t, f, a }, k) => {
			// Add the time column value to the csv data if this is the first iteration
			// or if we've somehow got a frame error from the api data and the row is missing
			if (!csvData[k]) {
				csvData[k] = {
					t: moment.utc(t).format(config.dspDateFormat)
				};
			}

			// Generate the f column names
			let namefkey = global
				? `Global Inactive Capacity`
				: `Combined Inactive Capacity`;
			// let nameakey = global
			// 	? `Global Activity Dispersion`
			// 	: `Combined Activity Dispersion`;
			let nameakey = global ? `Global Activity` : `Combined Activity`;

			// Add the column names to the csv headers if this is the first data iteration
			if (k === 0) {
				csvHeaders = [
					...csvHeaders,
					...[
						{
							label: namefkey,
							key: namefkey
						},
						{
							label: nameakey,
							key: nameakey
						}
					]
				];
			}
			// Set the f column on the csv data
			csvData[k][namefkey] = f;
			csvData[k][nameakey] = a;
		});

		return {
			csvFileName,
			csvHeaders,
			csvData
		};
	}

	buildRegionSelectError() {
		return (
			<Alert color="warning" className="p-2 mb-1">
				Please select at least one region.
			</Alert>
		);
	}

	buildShortDateRangeError() {
		return (
			<Alert color="warning" className="p-2 mb-1">
				Please select a date range of at least 10 days.
			</Alert>
		);
	}

	buildLoadingMessage() {
		return (
			<Fragment>
				<div className="h6 mb-3">Chart data loading...</div>
				<Progress animated value="100" />
			</Fragment>
		);
	}

	getSelectedDetail(arr = []) {
		const { global, selected, selectedGrouping, plantTypes, compareCombine } =
			this.state;
		if (arr.length === 1 && global && plantTypes.length !== 1) {
			return [{ key: 'global', ...config.globalRegion }];
		} else if (arr.length === 1 && arr[0] === 'combine') {
			return [{ key: 'combine', ...config.combinedRegion }];
		} else if (
			(plantTypes.length > 1 && compareCombine === 'compare') ||
			(plantTypes.length === 1 &&
				(selectedGrouping[selected]?.length === 1 || global))
		) {
			return arr.map((a) =>
				a === 'bfbof'
					? { key: 'bfbof', title: 'BF/BOF', name: 'BF/BOF', colour: '#36454F' }
					: { key: 'eaf', title: 'EAF', name: 'EAF', colour: '#FF5733' }
			);
		} else if (arr.length >= 1) {
			return arr.map((a) => ({
				key: a.replace(/[^a-zA-Z0-9]/g, '').toLowerCase(),
				title: a,
				name: a,
				colour:
					selectedGrouping[selected].find(({ name }) => name === a)?.colour ||
					''
			}));
		}
	}

	getChartData(dataArr) {
		const { global, plantTypes, compareCombine } = this.state;
		const details = this.getSelectedDetail(dataArr.map(({ name }) => name));

		// Build the datasets by iterating the allRegions array and reducing it with the smelterData compare field,
		// so that the original order of the regions is maintained
		return dataArr.map(({ name, data }) => {
			const detail = details.find(
				(d) => d.key === name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()
			);
			//filled point for combine
			const fCombinePointBackgroundColour =
				name === 'combine' ||
				(global && plantTypes.length !== 1 && compareCombine !== 'compare')
					? { pointBackgroundColor: detail.colour }
					: {};

			//fStyle will be added to existing active capacity style in configuration
			const fStyle = {
				label: detail.name,
				borderColor: detail.colour,
				...fCombinePointBackgroundColour
			};

			// let chartStyle = fStyle;
			// if (
			// 	(showActivity &&
			// 		global &&
			// 		plantTypes.length !== 1 &&
			// 		compareCombine !== 'compare') ||
			// 	(showActivity && name === 'combine')
			// ) {
			// 	chartStyle = {
			// 		...fStyle,
			// 		label: 'Activity',
			// 		borderColor: '#2d9147',
			// 		pointBackgroundColor: '#2d9147'
			// 	};
			// } else if (showActivity && name === 'combine') {
			// 	chartStyle = { ...fStyle, label: 'Combined Activity' };
			// }

			const addStyle = fStyle;

			return {
				data: data.map(({ t, c }) => {
					return {
						t: moment.utc(t).format(config.dspDateFormat),
						y: c
					};
				}),
				...genChartStyle(this.getChartType()),
				...addStyle
			};
		});
	}

	// Individual data lines shown for multiple regions
	buildLineChart() {
		const { steelSmelterCapacity } = this.props;

		const {
			compareCombine,
			global,
			selected,
			selectedGrouping,
			plantTypes,
			dataByPlant
		} = this.state;
		const isCombined =
			compareCombine === 'combine' &&
			!global &&
			selectedGrouping[selected]?.length > 1;

		const isCombinedPlants =
			(global || selectedGrouping[selected]?.length === 1) &&
			plantTypes.length !== 1 &&
			compareCombine !== 'compare';

		let apiData = '';

		if (isCombined) {
			apiData = [
				{
					name: 'combine',
					data:
						steelSmelterCapacity.combinedIdx ||
						(steelSmelterCapacity?.singleIdx &&
							steelSmelterCapacity.singleIdx[0]?.data) ||
						''
				}
			];
		} else if (isCombinedPlants && global) {
			apiData = [
				{
					name: 'Global',
					data: dataByPlant.combined
				}
			];
		} else if (isCombinedPlants && selectedGrouping[selected]?.length === 1) {
			apiData = [
				{
					name: selectedGrouping[selected][0].name,
					data: dataByPlant.combined
				}
			];
		} else if (
			!isCombinedPlants &&
			(global || selectedGrouping[selected]?.length === 1)
		) {
			apiData = plantTypes.map((name) => ({ name, data: dataByPlant[name] }));
		} else {
			apiData =
				steelSmelterCapacity.singleIdx ||
				selectedGrouping[selected]?.map(({ name }) => ({ name, data: '' }));
		}

		//compare data length gives info whether API response is received, combined data length doesn't contain that information
		if (!apiData || apiData.every(({ data }) => data === '')) {
			return this.buildLoadingMessage();
		}

		//check this condition
		if (apiData.every(({ data }) => data.length === 0)) {
			return;
		}
		const datasets = this.getChartData(apiData);

		return (
			<Card className="mb-3">
				{this.buildCardHeader()}
				<CardBody>
					<Container
						fluid
						className="pl-0 container-width"
						style={{ paddingRight: '21px' }}
					>
						<Row noGutters className="mt-0">
							<Col xs="12" sm="12">
								<Line data={{ datasets }} options={cChartOptions} redraw />
							</Col>
						</Row>
					</Container>
				</CardBody>
			</Card>
		);
	}

	render() {
		let { global, shortDateRange, selectedGrouping, selected } = this.state;
		return (
			<DocumentTitle title="GAINS | Steel Smelter Capacity">
				<div className="content-wrapper">
					<Container fluid>
						{/* Help, crumbs and the filter form */}
						{/* <div className="pull-right">{smeltersInlineHelpContent}</div> */}
						<Crumbs type="steel" path={[{ title: 'Smelter Capacity' }]} />
						{this.buildFilterForm()}

						{/* Error messages */}
						{shortDateRange && this.buildShortDateRangeError()}
						{!shortDateRange &&
							!global &&
							!selectedGrouping[selected]?.length &&
							this.buildRegionSelectError()}

						{/* Different chart types */}
						{!shortDateRange &&
							(selectedGrouping[selected]?.length > 0 || global) &&
							this.buildLineChart()}
					</Container>
				</div>
			</DocumentTitle>
		);
	}
}

const mapStateToProps = ({
	lastUploadDate,
	locations,
	groups,
	steelSmelterCapacity,
	steelSmelterUpdates
}) => {
	const { regions } = locations;
	const filteredGroups =
		regions.length > 1
			? groups
			: Object.keys(groups)
					.filter((objKey) => objKey !== 'Custom region')
					.reduce((newObj, key) => {
						newObj[key] = groups[key];
						return newObj;
					}, {});
	return {
		lastUploadDate,
		locations,
		groups: filteredGroups,
		steelSmelterCapacity: steelSmelterCapacity.data,
		steelSmelterUpdates
	};
};

const mapDispatchToProps = (dispatch) => ({
	fetchLastUploadDate: (filterData) =>
		dispatch(fetchLastUploadDate(filterData)),
	resetLastUploadDate: () => dispatch(resetLastUploadDate()),
	fetchSteelSmelterCapacity: (filterData) =>
		dispatch(fetchSteelSmelterCapacity(filterData)),
	resetSteelSmelterCapacity: () => dispatch(resetSteelSmelterCapacity()),

	fetchLocations: (filterData) => dispatch(fetchLocations(filterData)),
	resetLocations: () => dispatch(resetLocations()),

	fetchGroups: (filterData) => dispatch(fetchGroups(filterData)),
	resetGroups: () => dispatch(resetGroups())
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(SteelSmeltersCapacity));
