import { SHRINK_SMELTERS_ALL_TIME_MAP_DATA } from '../actions';

export default (state = {}, action) => {
	switch (action.type) {
		case SHRINK_SMELTERS_ALL_TIME_MAP_DATA:
			return action.payload;
		default:
			return state;
	}
};
