import React, { Component, Fragment } from 'react';
import {
	Alert,
	Button,
	Form,
	FormGroup,
	Input,
	InputGroup,
	InputGroupAddon,
	Label,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Toast,
	ToastBody,
	ToastHeader
} from 'reactstrap';
import { Auth } from 'aws-amplify';
import { LoaderButton } from 'components';

class PasswordModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			passwordIsChanging: false,
			oldPassword: '',
			showOldPassword: false,
			newPassword1: '',
			showNewPassword1: false,
			newPassword2: '',
			showNewPassword2: false,
			passwordErrorMsg: '',
			showToast: false
		};

		this.toggleToast = this.toggle.bind(this, 'showToast');
		this.toggleOldPassword = this.toggle.bind(this, 'showOldPassword');
		this.toggleNewPassword1 = this.toggle.bind(this, 'showNewPassword1');
		this.toggleNewPassword2 = this.toggle.bind(this, 'showNewPassword2');
	}

	handleMenuClose() {
		this.burgerMenu.classList.remove('show');
		this.burgerToggler.classList.add('collapsed');
		const ariaExpanded = this.burgerToggler.getAttribute('aria-expanded');
		if (ariaExpanded) {
			this.burgerToggler.setAttribute('aria-expanded', false);
		}
	}

	handlePasswordChange = (event) => {
		event.preventDefault();
		this.setState({
			passwordIsChanging: true
		});

		const { oldPassword, newPassword1 } = this.state;

		Auth.currentAuthenticatedUser()
			.then((user) => {
				return Auth.changePassword(user, oldPassword, newPassword1);
			})
			.then(() => {
				this.setState({
					passwordIsChanging: false,
					oldPassword: '',
					showOldPassword: false,
					newPassword1: '',
					showNewPassword1: false,
					newPassword2: '',
					showNewPassword2: false,
					passwordErrorMsg: '',
					showToast: true
				});
				setTimeout(this.toggleToast, 2500);
				this.props.toggle();
			})
			.catch(() => {
				this.setState({
					passwordIsChanging: false,
					passwordErrorMsg:
						'Your password change failed. Please check your existing password is correct and that your new password conforms to the required pattern.'
				});
			});
	};

	handlePasswordChangeCancel = (event) => {
		event.preventDefault();
		this.setState({
			passwordIsChanging: false,
			oldPassword: '',
			showOldPassword: false,
			newPassword1: '',
			showNewPassword1: false,
			newPassword2: '',
			showNewPassword2: false,
			passwordErrorMsg: ''
		});
		this.props.toggle();
	};

	handleChange = (event) => {
		this.setState({
			[event.target.id]: event.target.value,
			errorMsg: ''
		});
	};

	validatePasswordForm() {
		return (
			this.state.oldPassword.length > 0 &&
			this.state.newPassword1.length > 0 &&
			this.state.newPassword2.length > 0 &&
			this.state.newPassword1 === this.state.newPassword2
		);
	}

	toggle(toggler) {
		this.setState({
			[toggler]: !this.state[toggler]
		});
	}

	renderToast() {
		return (
			<Toast
				isOpen={this.state.showToast}
				aria-live="polite"
				aria-atomic="true"
				style={{ position: 'absolute', top: '50px', right: '50px' }}
			>
				<ToastHeader toggle={this.toggleToast}>
					<i className="fa fa-fw fa-info mr-2" />
					<strong className="mr-auto">Password Change</strong>
				</ToastHeader>
				<ToastBody>Your password has been successfully updated.</ToastBody>
			</Toast>
		);
	}

	render() {
		return (
			<Fragment>
				<Modal
					size="sm"
					isOpen={this.props.open}
					labelledBy="changePasswordModalLabel"
					aria-hidden="true"
				>
					<Form onSubmit={this.handlePasswordChange}>
						<ModalHeader toggle={this.handlePasswordChangeCancel}>
							<span id="changePasswordModalLabel">
								<h4 className="mb-0">Password Change</h4>
							</span>
						</ModalHeader>
						<ModalBody>
							<FormGroup>
								<Label for="oldPassword">Existing password</Label>
								<InputGroup>
									<Input
										id="oldPassword"
										type={this.state.showOldPassword ? 'text' : 'password'}
										placeholder="Enter existing password"
										autoFocus
										value={this.state.oldPassword}
										onChange={this.handleChange}
									/>
									<InputGroupAddon addonType="append">
										<Button
											outline
											color="secondary"
											onClick={this.toggleOldPassword}
										>
											<i
												className={
													this.state.showOldPassword
														? 'fa fa-eye fa-lg'
														: 'fa fa-eye-slash fa-lg'
												}
											/>
											<span className="sr-only">
												Toggle existing password display
											</span>
										</Button>
									</InputGroupAddon>
								</InputGroup>
							</FormGroup>

							<FormGroup>
								<Label for="newPassword1">New password</Label>
								<InputGroup>
									<Input
										id="newPassword1"
										type={this.state.showNewPassword1 ? 'text' : 'password'}
										placeholder="Enter new password"
										value={this.state.newPassword1}
										onChange={this.handleChange}
									/>
									<InputGroupAddon addonType="append">
										<Button
											outline
											color="secondary"
											onClick={this.toggleNewPassword1}
										>
											<i
												className={
													this.state.showNewPassword1
														? 'fa fa-eye fa-lg'
														: 'fa fa-eye-slash fa-lg'
												}
											/>
											<span className="sr-only">
												Toggle new password 1 display
											</span>
										</Button>
									</InputGroupAddon>
								</InputGroup>
								<Label for="newPassword2" className="sr-only">
									Repeat new password
								</Label>
								<InputGroup className="mt-1">
									<Input
										id="newPassword2"
										type={this.state.showNewPassword2 ? 'text' : 'password'}
										placeholder="Repeat new password"
										value={this.state.newPassword2}
										onChange={this.handleChange}
									/>
									<InputGroupAddon addonType="append">
										<Button
											outline
											color="secondary"
											onClick={this.toggleNewPassword2}
										>
											<i
												className={
													this.state.showNewPassword2
														? 'fa fa-eye fa-lg'
														: 'fa fa-eye-slash fa-lg'
												}
											/>
											<span className="sr-only">
												Toggle new password 2 display
											</span>
										</Button>
									</InputGroupAddon>
								</InputGroup>
							</FormGroup>
							<p className="help-block small text-muted font-italic mb-0">
								All fields are required. New passwords must match, should be
								more than 7 characters, include an uppercase letter, a lowercase
								letter, a special character and a number.
							</p>
							{this.state.passwordErrorMsg && (
								<Alert color="warning" className="mt-3 mb-0 pt-2 px-3 small">
									{this.state.passwordErrorMsg}
								</Alert>
							)}
						</ModalBody>
						<ModalFooter>
							<LoaderButton
								block
								color="primary"
								disabled={!this.validatePasswordForm()}
								type="submit"
								isLoading={this.state.passwordIsChanging}
								text="Change password"
								loadingText="Password change in progress…"
							/>
							<Button
								color="secondary"
								aria-label="Cancel"
								onClick={this.handlePasswordChangeCancel}
							>
								Cancel
							</Button>
						</ModalFooter>
					</Form>
				</Modal>
				{this.renderToast()}
			</Fragment>
		);
	}
}

export default PasswordModal;
