import { SELECT_STEEL_SMELTER, UNSELECT_STEEL_SMELTER } from '../../actions';

export default (state = [], action) => {
	switch (action.type) {
		case SELECT_STEEL_SMELTER:
			return [...state, action.payload];
		case UNSELECT_STEEL_SMELTER: {
			return state.filter((item) => item !== action.payload);
		}
		default:
			return state;
	}
};
