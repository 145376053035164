import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
	// Badge,
	Button,
	ButtonGroup,
	ButtonToggle,
	Collapse,
	Form,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown
} from 'reactstrap';
import { Link, NavLink } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import BodyClassName from 'react-body-classname';

import ReactSVG from 'react-svg';
import ReactGA from 'react-ga4';

import packageJson from '../../package.json';

import { Flag } from 'flag';
import menuConfig from './menuConfig';
import { TemplateUI } from 'components';
import {
	getAuthenticatedUserAttributes,
	getAuthenticatedUserGroups,
	// fetchSteelSmelterRegions,
	fetchSites,
	resetSites
} from '../actions';

import {
	ApiModal,
	ApiCredentialsModal,
	ContactModal,
	PasswordModal
} from '../components/modals';
import {
	getSecuredGroups,
	// getSalesOrEnterpriseTypes,
	getAlphaNumeric,
	capitaliseAll
} from '../utils';

import savantSteelLogo from 'images/gains-logo-white.svg';

import './Navigation.scss';

const securedElementGroups = getSecuredGroups();

class Navigation extends Component {
	constructor(props) {
		super(props);
		this.buttonToggleRef = React.createRef();

		this.state = {
			authGroups: [],
			isEnterprise: false,
			showSecuredElements: false,
			showPasswordModal: false,
			showContactModal: false,
			showApiModal: false,
			showApiCredModal: false,
			showMySmeltersModal: false,
			collapsed: true,
			subsiteTypes: [],
			sideMenuCondensed: false
		};

		this.togglePasswordModal = this.toggle.bind(this, 'showPasswordModal');
		this.toggleContactModal = this.toggle.bind(this, 'showContactModal');
		this.toggleApiModal = this.toggle.bind(this, 'showApiModal');
		this.toggleApiCredModal = this.toggle.bind(this, 'showApiCredModal');
		this.toggleMySmeltersModal = this.toggle.bind(this, 'showMySmeltersModal');

		this.userRole = '';
	}

	async componentDidMount() {
		const { type, alias } = this.props;

		const authUser = await Auth.currentAuthenticatedUser();
		this.userRole = authUser.attributes['custom:userRole'] || '';
		this.userSub = authUser.attributes['sub'] || '';

		const userAttributes = await getAuthenticatedUserAttributes();
		const { authenticatedUserGroups: authGroups } =
			await getAuthenticatedUserGroups();
		this.setState({
			...userAttributes,
			authGroups,
			showSecuredElements:
				securedElementGroups.length === 0 ||
				securedElementGroups.some((r) => authGroups.includes(r))
		});
		// const types = getSalesOrEnterpriseTypes(authGroups);

		// if (types.includes('steel')) {
		// 	this.props.fetchSteelSmelterRegions();
		// }
		this.props.fetchSites({ commodity: alias ? `${alias}` : `${type}` });
	}

	componentDidUpdate({ sites: prevSites, type: prevType }) {
		const { sites, type, alias } = this.props;

		if (type !== prevType) {
			this.props.fetchSites({ commodity: alias ? `${alias}` : `${type}` });
		}
		if (sites.length !== prevSites.length && type === 'steel') {
			const subsiteTypes = this.getSubsiteTypes();
			this.setState({ subsiteTypes });
		}
	}
	componentWillUnmount() {
		this.props.resetSites();
	}

	getSubsiteTypes = () => {
		const { sites } = this.props;
		return [
			...new Set(
				sites
					.filter(
						({ parent_id, parent_type_id }) =>
							parent_id &&
							sites.filter(
								({ id, site_type_id }) =>
									id === parent_id && site_type_id === parent_type_id
							).length
					)
					.map(({ stype }) => stype)
			)
		];
	};

	handleLogout = async () => {
		await Auth.signOut();
		ReactGA.event({
			category: 'authentication',
			action: 'logout',
			label: this.userSub
		});
		this.props.userHasAuthenticated(false, []);
		this.props.history.push('/');
	};

	handleMenuClose = () => {
		if (!this.state.collapsed) {
			this.toggle('collapsed');
		}
	};

	toggle(toggler) {
		this.setState({
			[toggler]: !this.state[toggler]
		});
	}

	generateTypePicker({ type }) {
		return (
			<Form inline>
				<ButtonGroup className="mr-3">
					<Button
						tag={Link}
						to="/steel/home"
						id="typeSteel"
						size="sm"
						className="steel"
					>
						{type === 'steel' ? (
							<i className="fa fa-circle mr-2" aria-hidden="true" />
						) : (
							<i className="fa fa-circle-thin mr-2" aria-hidden="true" />
						)}
						Steel
					</Button>
					<Button
						tag={Link}
						to="/ironOre/indices"
						id="typeIronOre"
						size="sm"
						className="ironore"
					>
						{type === 'ironOre' ? (
							<i className="fa fa-circle mr-2" aria-hidden="true" />
						) : (
							<i className="fa fa-circle-thin mr-2" aria-hidden="true" />
						)}
						Iron Ore
					</Button>
					{(this.userRole === 'superUser' || this.userRole === 'admin') && (
						<Button
							tag={Link}
							to="/scrapSteel/indices"
							id="typeScrapSteel"
							size="sm"
							className="scrapsteel"
						>
							{type === 'scrapSteel' ? (
								<i className="fa fa-circle mr-2" aria-hidden="true" />
							) : (
								<i className="fa fa-circle-thin mr-2" aria-hidden="true" />
							)}
							Scrap Steel
						</Button>
					)}
				</ButtonGroup>
			</Form>
		);
	}

	generateAccountMenu() {
		const { isEnterprise, showSecuredElements, initials, email } = this.state;
		return (
			<UncontrolledDropdown nav inNavbar>
				<DropdownToggle
					id="navbarAccountDropdownMenuLink"
					tag="button"
					type="button"
					data-toggle="dropdown"
					className="btn btn-badge"
				>
					{initials} <i className="fa fa-angle-down ml-2" />
				</DropdownToggle>
				<DropdownMenu right aria-labelledby="navbarAccountDropdownMenuLink">
					<DropdownItem header>{email}</DropdownItem>
					{showSecuredElements && (
						<Fragment>
							<DropdownItem divider />
							<DropdownItem onClick={this.toggleApiModal}>
								<i className="fa fa-fw fa-info mr-1" /> 3rd-party API Access
							</DropdownItem>
							<DropdownItem onClick={this.toggleApiCredModal}>
								<i className="fa fa-fw fa-shield mr-1" /> API Credentials
							</DropdownItem>
						</Fragment>
					)}
					{isEnterprise && (
						<DropdownItem onClick={this.toggleMySmeltersModal}>
							<i className="fa fa-fw fa-fire mr-1" /> My Smelters
						</DropdownItem>
					)}
					<DropdownItem divider />
					<DropdownItem onClick={this.toggleApiModal}>
						<i className="fa fa-fw fa-info mr-1" /> 3rd-party API Access
					</DropdownItem>
					<DropdownItem divider />
					<DropdownItem onClick={this.toggleContactModal}>
						<i className="fa fa-fw fa-at mr-1" /> Contact Earth-i
					</DropdownItem>
					<DropdownItem divider />
					<DropdownItem onClick={this.togglePasswordModal}>
						<i className="fa fa-fw fa-lock mr-1" /> Password Change
					</DropdownItem>
					<DropdownItem onClick={this.handleLogout}>
						<i className="fa fa-fw fa-sign-out mr-1" /> Logout
					</DropdownItem>
					<DropdownItem divider />
					<DropdownItem header className="smaller text-center p-0">
						App version: {packageJson.version}
					</DropdownItem>
				</DropdownMenu>
			</UncontrolledDropdown>
		);
	}

	renderMenuItem(menuItem) {
		const showLink =
			!menuItem.userRoles?.length ||
			(menuItem.userRoles?.length &&
				menuItem.userRoles.includes(this.userRole));
		// const item = (
		// 	<span className="nav-link-text">
		// 		{menuItem.title}
		// 		<sup>
		// 			<Badge color="primary" className="ml-1">
		// 				TRIAL
		// 			</Badge>
		// 		</sup>
		// 	</span>
		// );

		if (showLink) {
			return (
				<NavLink
					exact
					className="nav-link position-relative"
					to={menuItem.path}
				>
					<i className={'fa fa-fw mr-2 ' + menuItem.icon} />
					<span className="nav-link-text"> {menuItem.title}</span>
				</NavLink>
			);
		}
		// return (
		// 	<span className="nav-link disabled position-relative">
		// 		<i className={'fa fa-fw mr-2 ' + menuItem.icon} />
		// 		<span className="nav-link-text"> {menuItem.title}</span>
		// 	</span>
		// );
	}

	renderSubmenu(menuItem) {
		const { subsiteTypes, sideMenuCondensed } = this.state;

		return (
			<Fragment>
				<a
					// className={`nav-link nav-link-collapse collapsed position-relative`}
					//the request was to keep this open
					className={`nav-link position-relative`}
					data-toggle="collapse"
					// href="#collapseSecond"
					href="/#"
					onClick={() =>
						sideMenuCondensed ? this.buttonToggleRef.current.click() : {}
					}
				>
					<i className={`fa fa-fw mr-2 ${menuItem.icon}`} />
					<span className="nav-link-text">{menuItem.title}</span>
				</a>

				<Nav
					vertical
					// className={`sidenav-second-level collapse `}
					//the request was to keep this open
					className={`sidenav-second-level`}
					id="collapseSecond"
				>
					{menuItem.subpaths?.map(({ name, hasChildren }, i) => {
						const subpath = name.toLowerCase();
						if (!hasChildren)
							return (
								<NavItem
									key={`subpath-${i}`}
									active={this.isActiveLink(`${menuItem.path}/${subpath}`)}
									onClick={this.handleMenuClose}
								>
									<NavLink
										exact
										className="nav-link position-relative"
										to={`${menuItem.path}/${subpath}`}
									>
										<span className="nav-link-text"> {name}</span>
									</NavLink>
								</NavItem>
							);
						else
							return (
								<NavItem key={`subpath-${i}`}>
									<a
										className={`nav-link nav-link-collapse collapsed position-relative`}
										data-toggle="collapse"
										href="#collapseThird"
									>
										<span className="nav-link-text">{name}</span>
									</a>
									<Nav
										vertical
										className={`sidenav-third-level collapse`}
										id="collapseThird"
									>
										{subsiteTypes?.map((st, i) => {
											const title = capitaliseAll(getAlphaNumeric(st));

											return (
												<NavItem
													key={`st-${i}`}
													active={this.isActiveLink(
														`${menuItem.path}/${subpath}/${st}`
													)}
													onClick={this.handleMenuClose}
												>
													<NavLink
														exact
														className="nav-link position-relative"
														to={`${menuItem.path}/${subpath}/${st}`}
													>
														<span className="nav-link-text">{title}</span>
													</NavLink>
												</NavItem>
											);
										})}
									</Nav>
								</NavItem>
							);
					})}
				</Nav>
			</Fragment>
		);
	}

	isActiveLink = (path) => {
		const {
			location: { pathname }
		} = this.props;

		return (
			pathname === path ||
			pathname.substring(0, pathname.lastIndexOf('/')) === path
		);
	};

	generateMenuItems({ type }) {
		return menuConfig[type].map((menuItem, i) => {
			return (
				<NavItem
					key={i}
					active={
						!menuItem.subpaths?.length && this.isActiveLink(menuItem.path)
					}
					data-toggle="tooltip"
					data-placement="right"
					title={menuItem.title}
					onClick={!menuItem.subpaths?.length ? this.handleMenuClose : () => {}}
				>
					{menuItem.subpaths?.length
						? this.renderSubmenu(menuItem)
						: this.renderMenuItem(menuItem)}
				</NavItem>
			);
		});
	}

	toggleSideMenuState = () => {
		this.setState({ sideMenuCondensed: !this.state.sideMenuCondensed });
	};

	render() {
		const { type = 'cu' } = this.props;
		const {
			collapsed,
			showApiCredModal,
			authToken,
			showApiModal,
			showContactModal,
			showPasswordModal
		} = this.state;

		let logoSrc = savantSteelLogo;
		let homeLink = '/steel/home';

		return (
			<Fragment>
				<BodyClassName className={`fixed-nav sticky-footer bg-dark ${type}_bg`}>
					<Navbar id="mainNav" color="dark" dark expand="lg" fixed="top">
						<NavbarBrand tag={Link} to={homeLink} aria-current="page">
							<ReactSVG src={logoSrc} svgClassName="savant-logo" />
							<h1 className="sr-only">SAVANT</h1>
						</NavbarBrand>
						<Flag
							name="features.isPreview"
							render={() => (
								<span className="savant-logo-preview font-italic font-weight-bold">
									Preview
								</span>
							)}
						/>

						<NavbarToggler onClick={() => this.toggle('collapsed')} />
						<Collapse isOpen={!collapsed} navbar>
							<Nav className="ml-auto" navbar>
								<NavItem>
									<Nav vertical navbar className="navbar-sidenav">
										{this.generateMenuItems({ type })}
										<NavItem
											className="feedback-link"
											key="feedback-link"
											active={false}
											data-toggle="tooltip"
											data-placement="right"
											title="Give Us Feedback"
											onClick={this.handleMenuClose}
										>
											<NavLink
												exact
												className="nav-link position-relative"
												to={{
													pathname: 'https://earthi.typeform.com/gains-feedback'
												}}
												target="_blank"
											>
												<i className={'fa fa-fw mr-2 fa-link'} />
												<span className="nav-link-text">Give Us Feedback</span>
											</NavLink>
										</NavItem>
										<NavItem
											// className="feedback-link"
											key="report-issues-link"
											active={false}
											data-toggle="tooltip"
											data-placement="right"
											title="Report Issues"
											onClick={this.handleMenuClose}
										>
											<a
												className="nav-link position-relative"
												href="mailto:GAINSsupport@earthi.co.uk"
											>
												<i className={'fa fa-fw mr-2 fa-envelope'} />
												<span className="nav-link-text">Report Issues</span>
											</a>
										</NavItem>
									</Nav>
								</NavItem>

								<Nav className="sidenav-toggler">
									<NavItem>
										<ButtonToggle
											innerRef={this.buttonToggleRef}
											id="sidenavToggler"
											className="nav-link text-center"
											color="secondary"
											style={{ boxShadow: 'none' }}
											onClick={() => this.toggleSideMenuState()}
										>
											<i className="fa fa-fw fa-angle-left" />
											<span className="sr-only">Toggle sidenav</span>
										</ButtonToggle>
									</NavItem>
								</Nav>
								{this.generateTypePicker({ type })}
								{this.generateAccountMenu()}
							</Nav>
							<ApiCredentialsModal
								open={showApiCredModal}
								toggle={this.toggleApiCredModal}
								token={showApiCredModal ? authToken : ''}
							/>
							<ApiModal open={showApiModal} toggle={this.toggleApiModal} />
							<ContactModal
								open={showContactModal}
								toggle={this.toggleContactModal}
							/>
							<PasswordModal
								open={showPasswordModal}
								toggle={this.togglePasswordModal}
							/>
						</Collapse>
					</Navbar>
				</BodyClassName>
				<TemplateUI />
			</Fragment>
		);
	}
}

const mapStateToProps = ({ sites }) => {
	return {
		sites
	};
};

const mapDispatchToProps = (dispatch) => ({
	// fetchSteelSmelterRegions: () => dispatch(fetchSteelSmelterRegions()),
	fetchSites: (filterData) => dispatch(fetchSites(filterData)),
	resetSites: () => dispatch(resetSites())
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
