import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';

class ApiCredentialsModal extends Component {
	state = { copied: false };

	handleCloseModal = () => {
		this.setState({ copied: false });
		this.props.toggle();
	};

	render() {
		const { open, token } = this.props;
		return (
			<Modal isOpen={open} labelledBy="apiCredsModalLabel" aria-hidden="true">
				<ModalHeader toggle={this.handleCloseModal}>
					<span id="apiCredsModalLabel">
						<h4 className="mb-0">API Credentials</h4>
					</span>
				</ModalHeader>
				<ModalBody>
					<label htmlFor="authToken">Authorization token</label>
					<textarea
						className="form-control"
						id="authToken"
						rows="5"
						disabled={true}
						value={token}
					/>
				</ModalBody>
				<ModalFooter>
					{this.state.copied ? (
						<span
							className="mr-auto alert alert-success mb-0 padded-span"
							role="alert"
						>
							<i className="fa fa-check mr-3" />
							Copied
						</span>
					) : null}
					<CopyToClipboard
						text={token}
						onCopy={() => this.setState({ copied: true })}
					>
						<Button color="primary">Copy to clipboard</Button>
					</CopyToClipboard>
					<Button
						color="secondary"
						data-dismiss="modal"
						aria-label="Close"
						onClick={this.handleCloseModal}
					>
						Close
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
}

export default ApiCredentialsModal;
