import React, { Fragment } from 'react';

const startYear = 2018;
const currentYear = new Date().getFullYear();

export default () => (
	<Fragment>
		<footer className="sticky-footer">
			<div className="container">
				<div className="text-center">
					<small>
						Copyright<sup>©</sup> {startYear}
						{currentYear !== startYear && '-' + currentYear}{' '}
						<a
							href="http://earthi.space"
							target="_blank"
							rel="noopener noreferrer"
						>
							<strong>Earth-i Ltd.</strong>
						</a>{' '}
						All Rights Reserved.
					</small>
				</div>
			</div>
		</footer>
		<a className="scroll-to-top rounded" href="#page-top">
			<i className="fa fa-angle-up" />
			<span className="sr-only">Scroll to top</span>
		</a>
	</Fragment>
);
