import { Auth } from 'aws-amplify';

export const getAuthHeaders = async () => {
	try {
		const user = await Auth.currentAuthenticatedUser();
		const token = user.signInUserSession.idToken.jwtToken;
		return {
			Authorization: token,
			'X-Amz-User-Agent': 'Application'
		};
	} catch (err) {
		console.log('Error getting auth headers', err);
		return {
			Authorization: null
		};
	}
};

export const getAuthenticatedUserAttributes = async () => {
	try {
		const currentUser = await Auth.currentUserInfo();

		if (currentUser === null) {
			return;
		}

		const { username, attributes } = currentUser;
		const {
			idToken: { jwtToken: authToken }
		} = await Auth.currentSession();
		let { given_name, family_name, email } = attributes;
		const givenName = given_name.trim();
		const familyName = family_name.trim();
		email = email.trim();

		return {
			username,
			givenName,
			familyName,
			email,
			initials:
				givenName.substr(0, 1).toUpperCase() +
				familyName.substr(0, 1).toUpperCase(),
			authToken
		};
	} catch (err) {
		console.log('Error getting authenticated user attributes', err);
		return {};
	}
};

export const getAuthenticatedUserGroups = async () => {
	try {
		const { idToken } = await Auth.currentSession();
		return {
			authenticatedUserGroups: idToken.decodePayload()['cognito:groups'] || []
		};
	} catch (err) {
		console.log('Error getting authenticated user groups', err);
		return [];
	}
};
